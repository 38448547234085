import React, { useState, useRef, useEffect, Children } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./HomePageCompOne.css";

import StaticDataService from "../../services/staticData";
import { M2MApiUrl } from "../../services/staticData"; 


const HomePageCompOne = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSlideMobile, setCurrentSlideMobile] = useState(0);
  const sliderRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [mainBanners, setMainBanners] = useState(null);
  const [mainBannersMobile, setMainBannersMobile] = useState(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: currentSlide,
    arrows: false ,
    // fade: true,
    afterChange: (index) => {
      setCurrentSlide(index);
      setCurrentSlideMobile(index); 
    },
    beforeChange: (current, next) => {
      setCurrentSlide(next);
    },
  };

  const handleDotClick = (index) => {
    setCurrentSlide(index);
    sliderRef.current.slickGoTo(index);
  };

  const [totalSlides, setTotalSlides] = useState(0);

  useEffect(() => {
    
    StaticDataService.HomeMainBanners()
      .then((response) => {
        if (response.status !== "error") {
          const desktopimages = processBannerData(response.webmainbanner);
          const responsiveimages = processBannerData(response.webmainbannermobile);
          setMainBanners(desktopimages);
          setMainBannersMobile(responsiveimages);
        } else {
          /* setError(response.message); */
        }
      })
      .catch((err) => {
        //setError(err.message);
        //setLoading(false);
      });
    
  }, []);

  const processBannerData = (data) => {
    return data.map(banner => ({
      uri: M2MApiUrl + banner.image.replace('public/',"storage/"),
      redirectTo: banner.redirect_url,
    }));
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
  
    window.addEventListener("resize", handleResize);
  
    if (sliderRef.current && mainBanners && mainBannersMobile) {
      const newTotalSlides = mainBanners.length || 0;
      setTotalSlides(newTotalSlides);
    }
  
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [sliderRef, mainBanners, mainBannersMobile]);
  

  var lineHight = 400 / totalSlides;

  const generatePagination = () => {
    const paginationItems = [];

    for (let i = 0; i < totalSlides; i++) {
      if (i > 0) {
        paginationItems.push(
          <div className="pagination-line" style={{ height: `${lineHight}px` }} key={`line-${i}`}/>
        );
      }

      paginationItems.push(
        <div className={`pagination-dot ${currentSlide === i ? "active" : ""}`} onClick={() => handleDotClick(i)} key={`dot-${i}`}>
          {currentSlide === i ? i + 1 : ""}
        </div>
      );
    }

    return paginationItems;
  };
  let mergedArray = []
  //const mergedArray = [...mainBanners, ...mainBannersMobile];
  if(mainBanners){
    mergedArray = mainBanners.map((value, index) => ({
      //[index]: [value, mainBannersMobile[index]]
      [index]: { main: value, mobile: mainBannersMobile[index] }
    }));
  }
  
  //console.log("mergedArray : ", mergedArray);
  return (
    <div className="slider_containers">
      <Slider {...settings} ref={sliderRef}>
        {mergedArray && mergedArray.map((slide, index) => (
          <div className="home-hero-banner-main" key={index}>
            {windowWidth <= 600 ? (
              <div className="slick_slide_common">
                <img src={slide[index]['mobile']['uri']} alt={`slide-${index}`} />
              </div>
            ) : (
              <div className="slick_slide_common">
                <img src={slide[index]['main']['uri']} alt={`slide-${index}`} />
              </div>
            )}
          </div>
        ))}
      </Slider>
      
          {windowWidth <= 600 ? (
            <div className="Pagination_Mobile"></div>
          ) : (
            // Desktop view: Dot and line pagination
            <div className="pagination-dot-container">
              <div className="pagination">
                <div className="pagination-vertical">{generatePagination()}</div>
              </div>
            </div>
          )}
       
    </div>
  );
  
};

export default HomePageCompOne;
