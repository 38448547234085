// GlobalStateContext.js
import React, { createContext, useState, useContext } from 'react';

const GlobalStateContext = createContext();

export const useGlobalState = () => useContext(GlobalStateContext);

export const GlobalStateProvider = ({ children }) => {
  const [globalArray, setGlobalArray] = useState([]);

  const addToGlobalArray = (newValue) => {
    setGlobalArray(prevArray => [...prevArray, newValue]);
  };

  const removeFromGlobalArray = (index) => {
    setGlobalArray(prevArray => prevArray.filter((_, i) => i !== index));
  };

  return (
    <GlobalStateContext.Provider value={{ globalArray, addToGlobalArray, removeFromGlobalArray }}>
      {children}
    </GlobalStateContext.Provider>
  );
};
