import React, { useEffect, useState } from 'react';
import './Onward_M2M_Page.css';
import MainNavbar from '../../Components/Navbar/MainNavbar';
import FotterAll from '../../Components/Footer/FotterAll';
import { Link } from "react-router-dom";
import StaticDataService from "../../services/staticData";
import Meta from '../../Components/Meta';

const Onward_M2M_Page = () => {
  const [seoMeta, setSeoMeta] = useState(null);
  const [expandedIndex, setExpandedIndex] = useState(0);
  const [expandedIndexMob, setExpandedIndexMob] = useState(0);

  // Define an array of objects containing image URLs, headings, and corresponding text
  const imagesWithText = [
    { heading: 'Seating Areas', image: '/assets/img/Gallery1/Seating_Areas/Skoda_Kushaq_Zone/new_seat_area2.jpg', text: 'Choose from Skoda Kushaq Zone, Skoda Kylaq Deck, and Skoda Kodiaq Lounge, three distinct seating options offering unique features that elevate your comfort and fun.' },
    { heading: 'Dining', image: 'assets/img/Gallery1/Dining/Meemo_s_Cafe/new_dining3.jpg', text: 'Add more joy to your sails with delicious food and refreshing beverages at our onboard cafe and bar.' },
    { heading: 'Vehicle Deck', image: '/assets/img/Gallery1/Vehicle_Deck/new_up_vechicle_deck2.jpg', text: 'Our Ferry offers a spacious vehicle deck, effortlessly accommodating over 125 cars, two-wheelers, and more for a seamless travel experience.' },
    { heading: 'Pet Zone', image: '/assets/img/Gallery1/Pet_Zone/new_up_petzone3.jpg', text: 'Our designated pet zone provides a safe and comfortable space for your pet to unwind and enjoy the journey.' },
    { heading: 'Events', image: '/assets/img/Gallery1/Events/Sunburn/new_events_sunburn3.jpg', text: 'Host unique events at sea! M2M Ferries offer adaptable spaces for weddings, school trips, corporate events and even music festivals.' },
    { heading: 'Oceanic Views', image: '/assets/img/Gallery1/Oceanic_Views/new_oceanic_view1.jpg', text: 'M2M Ferries offer breathtaking oceanic views, the perfect backdrop for capturing unforgettable pictures and videos' },
  ];

  const handleImageHover = (index) => {
    setExpandedIndex(index);
    setExpandedIndexMob(index === expandedIndexMob ? -1 : index);
  };

  // AutoMatic Scrolling Top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  //FOR META TAGS
  useEffect(() => {
    StaticDataService.SeoMetaServices('M2MExperience')
    .then((response) => {
     
      if (response.status !== "error") {
        
        setSeoMeta(response.seoMeta[0]);
       
      } else {
        /* setError(response.message); */
      }
    })
    .catch((err) => {
      //setError(err.message);
    });
  }, []);

  return (
    <div>
      {seoMeta ? (
        <Meta
          title={seoMeta.title}
          description={seoMeta.description}
          keywords={seoMeta.keywords}
          site_name = {seoMeta.site_name}
          canonicalUrl = {seoMeta.canonicalUrl}
          url={seoMeta.url}
          image = {seoMeta.image}
        />
      ):null}
      <div className="navbarContainer-sticky Main-navbar-border">
        <MainNavbar />
      </div>
      <div className="Onward_M2M_Section">
        <div className="Onward_M2M_Section_Heading">
          <h1>M2M Experience</h1>
          <p>More than just a Ferry ride, it's a feeling. Get a glimpse of the comfort, convenience, and unforgettable moments that await you</p>
        </div>
        <div className="Onward_M2M_WhiteLinearGradint"></div>
      </div>

      {/* For Desktop Section start */}
      <div className='carousel-container-Desk'>
        <div className="carousel-container">
          {imagesWithText.map((item, index) => (
            <div
              key={index}
              className="image-box"
              onMouseEnter={() => handleImageHover(index)}
              onMouseLeave={() => handleImageHover(0)}
              style={{ width: expandedIndex === index ? '50%' : '12%' }}
            >
              <div className="imageContent" style={{ width: '100%', height: '633px', objectFit: 'cover', backgroundImage: `url(${item.image})`,backgroundRepeat:'no-repeat' }}>
                <div className='imageContent_Main'>
                  <div className={expandedIndex === index ? 'imageContentDiv1 expanded' : 'imageContentDiv1'}>
                    <h2>{(index + 1).toString().padStart(2, '0')}</h2>
                  </div>
                  {expandedIndex === index && (
                    <div className='imageContentDiv2'>
                      <h1>{item.heading}</h1>
                      <p>{item.text}</p>
                      <Link to={`/events?tab=${index}`}>
                        <button className='GalleryButton'>Know More</button>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* For Mobile Section start */}
      <div className="carousel-container-mob">
        {imagesWithText.map((item, index) => (
          <div key={index} className="image-box" onClick={() => handleImageHover(index)}
            style={{
              width: '100%',
              height: expandedIndexMob === index ? '355px' : '100px',
              marginBottom: '10px',
              backgroundImage: `url(${item.image})`
            }}
          >
            <div className='imageContentDiv1'>
              <h2>{(index + 1).toString().padStart(2, '0')}</h2>
            </div>
            {expandedIndexMob === index && (
              <div className='imageContentDiv2'>
                <h1>{item.heading}</h1>
                <p>{item.text}</p>
                <Link to={`/events?tab=${index}`}>
                   <button className='GalleryButton'>Know More</button>
                </Link>
              </div>
            )}
          </div>
        ))}
      </div>
      <FotterAll />
    </div>
  );
};

export default Onward_M2M_Page;
