import React, { useState, useEffect } from 'react'
import "./Travel_Guide.css"
import MainNavbar from '../../Components/Navbar/MainNavbar'
import FotterAll from '../../Components/Footer/FotterAll'
import StaticDataService from '../../services/staticData'
import Meta from '../../Components/Meta';

const Travel_Guide = () => {
    const [seoMeta, setSeoMeta] = useState(null);

    //FOR META TAGS
    useEffect(() => {
        StaticDataService.SeoMetaServices('TravelGuide')
        .then((response) => {
        
        if (response.status !== "error") {
            
            setSeoMeta(response.seoMeta[0]);
        
        } else {
            /* setError(response.message); */
        }
        })
        .catch((err) => {
        //setError(err.message);
        });
    }, []);


    // const [pageContent, setPageContent] = useState('');
    const [showNav, setShowNav] = useState('true');
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const show_nav = params.get('show_nav');
        if(show_nav)
        setShowNav(show_nav); 
    }, []); 
    const scrollToABC = () => {
        document.getElementById('abcDiv').scrollIntoView({ behavior: 'smooth' });
    };

    const scrollToXYZ = () => {
        document.getElementById('xyzDiv').scrollIntoView({ behavior: 'smooth' });
    };

    // useEffect(()=>{
    //     StaticDataService.StaticData()
    //     .then((response) => {
    //         // console.log(response.data[0]);
    //         setPageContent(response.data[1].description);
    //     })

    // })
    
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
    }, []);
    
  
  return (
    <>
        {seoMeta ? (
            <Meta
            title={seoMeta.title}
            description={seoMeta.description}
            keywords={seoMeta.keywords}
            site_name = {seoMeta.site_name}
            canonicalUrl = {seoMeta.canonicalUrl}
            url={seoMeta.url}
            image = {seoMeta.image}
            />
        ):null}
        <div className='TravelGuide_Main'>
            {showNav == 'true' ? (
                <>
                    <div className="navbarContainer-sticky Main-navbar-border">
                        {showNav == 'true' ? <MainNavbar isHomepage={false} /> : null}
                    </div>
                    <div className="TravelGuide_Section">
                        <div className="TravelGuide_Section_Heading">
                            <h1>Travel Guide</h1>
                            {/* <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed Lorem ipsum dolor sit amet, consetetur</p> */}
                        </div>
                        <div className="TravelGuide_WhiteLinearGradint"></div>
                    </div>
                </>
            ) : null}
            {/* <div className='TravelGuide_Container' dangerouslySetInnerHTML={{ __html: pageContent}}></div> */}
            <div className='TravelGuide_Container'>
                
                <div className='TravelGuide_Tabs'>
                    <div  onClick={scrollToABC} className='TravelGuide_Tabs_div'>
                        <img src="/assets/img/Faq/FaqBlack.png" alt="asad"/>
                        <h2>Mandwa Terminal</h2>
                    </div>
                    <div onClick={scrollToXYZ}  className='TravelGuide_Tabs_div'>
                    <img src="/assets/img/Faq/FaqBlack.png" alt="asad"/>
                        <h2>Ferry Wharf Terminal</h2>
                    </div>
                </div>
                <div id="abcDiv" className="TravelGuide_Heading_div1">
                    <div className="Eight_CompHeading_service">
                        <h1>How To Reach Mandwa Terminal</h1>
                        <div className="TravelGuide_Heading_BottamLine">
                            <div className="section section-1_TravelGuide"></div>
                            <div className="section section-2_TravelGuide"></div>
                            <div className="section section-3_TravelGuide"></div>
                        </div>
                        <p>Our Mandwa Terminal is located next to the current Mandwa Jetty</p>
                    </div>
                    <div className='Maps_IFrame'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3776.8344936234703!2d72.87917241489708!3d18.805527787243708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTjCsDQ4JzE5LjkiTiA3MsKwNTInNTIuOSJF!5e0!3m2!1sen!2sin!4v1616837987847!5m2!1sen!2sin" style={{borderRadius:'30px'}} allowFullScreen="" loading="lazy"></iframe>
                    </div>
                    <div className='TravleGuide_Content_Info'>
                        <div className='Form_Content'>
                            <h1>FROM ALIBAUG:</h1>
                            <ol>
                                <li>Head north on Alibaug Rewas Road toward Savta Mali Road.</li>
                                <li> At Dr.Vhorkate Shatayu Aashram Hospital, continue onto Alibag - Rewas Road.</li>
                                <li>Continue straight onto Mandwa Jetty Road.</li>
                                <li>Slight left to stay on Mandwa Jetty Road.</li>
                                <li>At the end of Mandwa Jetty Road, Take a left to enter the Mandwa Ropax Terminal.</li>
                            </ol>
                        </div>
                        <div className='ByTransport'>
                            <h1>BY PUBLIC TRANSPORT:</h1>
                            <div>
                                <p>Bus: Take the hourly MSRTC Bus (Alibaug-Rewas via Chondhi route) from Alibaug to Mandwa Phata bus stop and take private auto rickshaw to Mandwa Jetty (Limited Availability).</p>
                                <br />
                                <p>Private Hire: Direct hire Auto rickshaw from Alibaug city to Mandwa Jetty.</p>
                            </div>
                        </div>
                        <div className='Address_Content'>
                            <h1>BY PUBLIC TRANSPORT:</h1>
                            <p>
                                <span style={{ display: 'block' }}>M2M Mandwa Terminal</span>
                                <span style={{ display: 'block' }}>M2M Ferries Mandwa Ropax Terminal Ropax Service Jetty,</span>
                                <span style={{ display: 'block' }}>Mandwa Jetty Road,</span>
                                <span style={{ display: 'block' }}>Mandwa. Maharashtra 402201</span>
                                <a href="https://goo.gl/maps/KLUkGuaNSJSX7RbW7" target="_blank" rel="noopener noreferrer">https://goo.gl/maps/KLUkGuaNSJSX7RbW7</a>
                            </p>
                        </div>
                    </div>
                </div>

                <div id="xyzDiv" className="TravelGuide_Heading_Div2">
                    <div className="Eight_CompHeading_service">
                        <h1>How To Reach Ferry Wharf Terminal</h1>
                        <div className="TravelGuide_Heading_BottamLine">
                            <div className="section section-1_TravelGuide"></div>
                            <div className="section section-2_TravelGuide"></div>
                            <div className="section section-3_TravelGuide"></div>
                        </div>
                        <p>Our Mumbai Terminal is located in Princess Docks (Bhaucha Dhakka) next to the Domestic Cruise Terminal (DCT).</p>
                    </div>
                    <div className='Maps_IFrame'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3773.409284683142!2d72.8476821!3d18.9575236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7cf49a25bc9e1%3A0xa27ce6f71acb31ad!2sM2M%20Ferries%20Ro-Pax%20Terminal!5e0!3m2!1sen!2sin!4v1723529167315!5m2!1sen!2sin" style={{borderRadius:'30px'}} allowFullScreen="" loading="lazy"></iframe>                
                    </div>
                    <div className='TravleGuide_Content_Info'>
                        <div className='Form_Content'>
                            <h1>FROM NORTH MUMBAI:</h1>
                            <p>If you are travelling via P D’Mello Road, </p>
                            <ol>
                                <li>Turn left at the traffic lights on P D’Mello Road towards Princess Docks and continue going straight under the eastern freeway.</li>
                                <li>At the end of the road, take a right turn and continue straight towards Bhaucha Dhakka.</li>
                                <li>At the next traffic lights, proceed straight through the white gates next to the Police Chowky.</li>
                                <li>At the end of the road, take a left towards the Ropax Ferry Terminal.</li>
                            </ol>
                        </div>
                        <div className='Form_Content'>
                            <p>If you are travelling via the Eastern Freeway,</p>
                            <ol>
                                <li>Take the exit for Yellow Gate.</li>
                                <li>At the end of the road, take a right turn and continue straight towards Bhaucha Dhakka</li>
                                <li>At the next traffic light, take a right turn through the white gates next to the police Chowky.</li>
                                <li>At the end of the road, take a left towards the Ropax Ferry Terminal.</li>
                            </ol>
                        </div>
                        <div className='Form_Content'>
                            <h1>FROM SOUTH MUMBAI:</h1>
                                <ol>
                                    <li>Take P D’Mello Road towards Princess Docks and avoid taking the eastern freeway.</li>
                                    <li>Continue straight through the traffic lights.</li>
                                    <li>At the next traffic light, take a right turn and continue straight towards Bhaucha Dhakka.</li>
                                    <li>At the next traffic lights, proceed straight through the white gates next to the Police Chowky.</li>
                                    <li>At the end of the road, take a left towards the Ropax Ferry Terminal.</li>
                                </ol>
                        </div>
                        <div className='ByTransport'>
                            <h1>BY PUBLIC TRANSPORT:</h1>
                            <div>
                                <p>Bus: You can take BEST Bus no. 44, 46, 48, 50, or 135 get off at the Mallet Bunder bus stop. From here, the Ropax terminal is 5min walking distance.</p>
                                <br />
                                <p>Train: We are located 15min walking distance from Dockyard station. See where Dockyard train station is in relation to us on <a href="https://maps.app.goo.gl/QR27JCn2D12nsUxRA" target="_blank" rel="noopener noreferrer">https://maps.app.goo.gl/QR27JCn2D12nsUxRA</a></p>        
                            </div>
                        </div>
                        <div className='Address_Content'>
                            <h1>BY PUBLIC TRANSPORT:</h1>
                            <p>
                                <span style={{ display: 'block' }}>M2M Ferries Ro-Pax Terminal</span>
                                <span style={{ display: 'block' }}>Near Ferry Wharf, Bhaucha Dhakka,</span>
                                <span style={{ display: 'block' }}>Princess Dock,</span>
                                <span style={{ display: 'block' }}>Mumbai Port Trust, Mazgaon,</span>
                                <span style={{ display: 'block' }}>Mumbai-400010</span>
                                <a href="https://maps.app.goo.gl/QR27JCn2D12nsUxRA" target="_blank" rel="noopener noreferrer">https://maps.app.goo.gl/QR27JCn2D12nsUxRA</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        {showNav == 'true' ? <div>
            <FotterAll />
        </div> : null}
        
        </div>
    </>
  )
}

export default Travel_Guide