import React from "react";
import "./Experience_Ferries.css";

const Experience_Ferries = () => {
  return (
    <div className="Experince_continer">
      <div className="Experince_heading">
         <div className="Experience_Complogo">
            <div className="Experince_heading_TextDecoration">
              <h1>Welcome To M2M Ferries</h1>
              <div className="Expernice_decortive">
                <div className="section section-1_Exp"></div>
                <div className="section section-2_Exp"></div>
                <div className="section section-3_Exp"></div>
              </div>
            </div>
         </div>
      </div>


      <div className="Experince_content">
        <div className="Experince_content_DivOne">
          <div className="image-container-BigImage">
            <img src="/assets/img/Experince/Component.png" alt="Your Image" />
            <div className="image-overlay"></div>
            <div className="text">
              <h1>Travel Year-round</h1>
              <p>Maharashtra's 1st Ro-Pax Ferry that’s operational year-round, even during monsoons.</p>
            </div>
          </div>
        </div>
        <div className="Experince_content_DivTwo">
          <div className="image-container-SmallImage">
            <img src="/assets/img/Experince/Veh.png" alt="Vehicle Deck"/>
            <div className="image_overlay_DivTwo"></div>
            <div className="text_DivTwo">
              <h1>Vehicle Deck</h1>
              <p>Our vehicle deck can accommodate over 120 cars, two-wheelers, and even buses.</p>
            </div>
          </div>
          <div className="image-container-SmallImage">
            <img src="/assets/img/Experince/SkodaRoom.jpeg" alt="Lounge Area" />
            <div className="image_overlay_DivTwo"></div>
            <div className="text_DivTwo" id="LougeArea_Text">
              <h1>Lounge Area</h1>
              <p>Our spacious indoor lounge area is equipped with all the amenities for a comfortable sail.</p>
            </div>
          </div>
          <div className="image-container-SmallImage">
            <img src="/assets/img/Experince/caf.png" alt="Cafeteria" />
            <div className="image_overlay_DivTwo"></div>
            <div className="text_DivTwo">
              <h1>Cafeteria</h1>
              <p>Our cafeteria is ready with your favourite bites & refreshments to add more flavour to your sails.</p>
            </div>
          </div>
          <div className="image-container-SmallImage">
            <img src="/assets/img/Experince/Pet.png" alt="Pet Area" />
            <div className="image_overlay_DivTwo"></div>
            <div className="text_DivTwo">
              <h1>Pet Area</h1>
              <p>Our exclusive pet zone invites your four-legged best friends to join you on your adventures!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience_Ferries;
