import React, { useEffect, useState } from "react";
import "./Login.css";
import FotterAll from "../../Components/Footer/FotterAll";
import { Link } from "react-router-dom";
import MainNavbar from "../../Components/Navbar/MainNavbar";
import Modal from 'react-modal';
import StaticDataService from "../../services/staticData";
import UserDataService from "../../services/userData";
import Select from 'react-select';
import Meta from '../../Components/Meta';

const Login = () => {
  Modal.setAppElement('#root');

  const [seoMeta, setSeoMeta] = useState(null);

  //FOR META TAGS
  useEffect(() => {
      StaticDataService.SeoMetaServices('Login')
      .then((response) => {
      if (response.status !== "error") {
          setSeoMeta(response.seoMeta[0]);
      } else {
          /* setError(response.message); */
      }
      })
      .catch((err) => {
      //setError(err.message);
      });
  }, []);

  // Login for or  Otp Section will show us
  const [loginformSectionVisible, setLoginFormSectionVisible] = useState(true);
  const [loginnewSectionVisible, setLoginNewSectionVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  const [resendTimer, setResendTimer] = useState(30); 
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [showResendText, setShowResendText] = useState(true);


  useEffect(() => {
    const storedUserData = JSON.parse(localStorage.getItem("userData"));
    if(storedUserData)
      window.location = "personal-information";
  },[])
  useEffect(() => {
    // Start the timer when isTimerRunning is true
    let timer;
    if (isTimerRunning) {
      timer = setInterval(() => {
        setResendTimer(prevTimer => prevTimer - 1);
      }, 1000);
    }

    // Clear the timer when it reaches 0
    if (resendTimer === 0) {
      clearInterval(timer);
      setIsTimerRunning(false);
      setShowResendText(true);
      setResendTimer(30); 
    }

    // Clean up function
    return () => clearInterval(timer);
  }, [isTimerRunning, resendTimer]);
  
  const goToBack = () => {
    setLoginFormSectionVisible(true);
    setLoginNewSectionVisible(false);
  };
  

  // country code start
  const [selectedOption, setSelectedOption] = useState(null);
  const [countryCodes, setCountryCodes] = useState([]);

  useEffect(() => {
    fetch('assets/CountryCodes.json')
      .then(response => response.json())
      .then(data => {
        const options = data.map(country => ({
          value: country.value,
          label: country.label 
        }));
        setCountryCodes(options);
        //set default
        const defaultOption = options.find(option => option.value === '+91');
        setSelectedOption(defaultOption);
      })
      .catch(error => {
        console.error('Error fetching country codes:', error);
      });
  }, []);

  // Handle selection
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption); 
  };

  const handleInputChange = (e) => {
    const input = e.target.value.replace(/[^0-9]/g, "");
    const fixinputNumbers = input.slice(0, 10);
    setPhoneNumber(fixinputNumbers);

    // Display the tick mark if the input length is 10
    /* const tickMark = document.getElementById("tickMark");
    if (input.length === 10) {
      tickMark.style.display = "inline";
    } else {
      tickMark.style.display = "none";
    } */
  };

 // For Browser ALerts
 const [isBrowserAlerts, setIsBrowserAlerts] = useState('');
 const closeBrowserModal = () => {
   setIsBrowserAlerts('');
 };
//  for alert wrong numaber case
 const [isWrongNumberAlerts, setIsWrongNumberAlerts] = useState('');
 const closeWrongNumberModal = () => {
  setIsWrongNumberAlerts('');
  window.location = "signup";
 };

  const handleGetOTPClick = () => {
    if (phoneNumber.length === 10) {
      var rawData = JSON.stringify({ mobile: phoneNumber,country_code: selectedOption ? selectedOption.value : '',  step: "login" });
      UserDataService.requestOtp(rawData)
        .then((response) => {
          if (response.success) {
            setLoginFormSectionVisible(false);
            setLoginNewSectionVisible(true);
            // Start the timer when OTP is sent
             setIsTimerRunning(true);
             setShowResendText(false);
          } else {
            setIsWrongNumberAlerts(response.message);
            //window.location = "signup";
          }
        })
        .catch((err) => {
          console.error("Error:", err);
        });
      //
    } else {
      setIsBrowserAlerts("Please enter a valid mobile number");
    }
  };

  // Example handler for OTP submission
  const handleOTPSubmission = () => {
    const stringOtp = enteredOTP.map((value) => value).join("");

    //return false;
    if (enteredOTP.length === 6) {
      var rawData = JSON.stringify({ mobile: phoneNumber, otp: stringOtp });
      UserDataService.userLogin(rawData)
        .then((response) => {
          if (response.success) {
            var userData = response.user_profile;
            localStorage.setItem("userData", JSON.stringify(userData));
            window.location = "personal-information";
          } else {
              setIsBrowserAlerts(response.message);
              setEnteredOTP(["", "", "", "", "", ""]);
              return false;
          }
        })
        .catch((err) => {
        });
      /* setIsBrowserAlerts("OTP is Correct");
       */
    } else {
      // setIsBrowserAlerts("Incorrect OTP. Please try again.");
      // setEnteredOTP(["", "", "", "", "", ""]);
    }
  };
  

  // otp section
  const [enteredOTP, setEnteredOTP] = useState(["", "", "", "", "", ""]);
  const handleOTPChange = (index, value) => {
    // Allow only numeric input
    const numericValue = value.replace(/\D/g, "");

    const newOTP = [...enteredOTP];
    newOTP[index] = numericValue;

    // Move focus to the next input box if a digit is entered
    if (numericValue.length === 1 && index < 5) {
      const nextIndex = index + 1;
      const nextInput = document.getElementById(`otpInputBox${nextIndex}`);
      if (nextInput) {
        nextInput.focus();
      }
    } else if (numericValue.length === 0 && index > 0) {
      // Move focus to the previous input box if the current box is empty
      const prevIndex = index - 1;
      const prevInput = document.getElementById(`otpInputBox${prevIndex}`);
      if (prevInput) {
        prevInput.focus();
      }
    }
    setEnteredOTP(newOTP);
  };
  // otp section functlity end


  const handleResendOTP = () => {
    // Resend OTP to the same mobile number
    handleGetOTPClick();
    // Reset timer
    setResendTimer(30);
    // Start timer again
    setIsTimerRunning(true);
  };


  // Login Temrs and Condtion Modal
  // onModel will be open(Popup)
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  const [TemsData,setTermsData] = useState()
  useEffect(()=>{
    StaticDataService.StaticData()
    .then((response) => {
      setTermsData(response.data[3].description)
    })
    .catch((err) => {
      console.log(err,"error")
    });

  },[])

  return (
    <div className="Login_Continer">
       {seoMeta ? (
            <Meta
            title={seoMeta.title}
            description={seoMeta.description}
            keywords={seoMeta.keywords}
            site_name = {seoMeta.site_name}
            canonicalUrl = {seoMeta.canonicalUrl}
            url={seoMeta.url}
            image = {seoMeta.image}
            />
      ):null}
      
      <div className="navbarContainer-sticky Main-navbar-border">
        <MainNavbar isHomepage={false} />
      </div>
      {loginformSectionVisible && (
        <div className="Login_Continer">
          <div className="Login_Content">
            <div className="Login_Heading">
              <h1>Log In</h1>
              <p>Welcome To M2M Ferries. Please Enter Your Details To Log In</p>
            </div>

            {/* mobile number start */}
            <div className="Login_label_guest_form">
              <label htmlFor="input4">Mobile Number</label>
            </div>
            <div className="Login_countryCode">
              {" "}
              <div className="Singup_custom_select_confirm">
                <Select
                  value={selectedOption}  
                  onChange={handleChange}  
                  options={countryCodes}   
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused ? "white" : "white",  
                      boxShadow: "none", 
                      '&:hover': { borderColor: "white" }, 
                      width:'110px',
                      border: "0.5px solid #767d8f80",
                      color: "#101010",
                      borderRadius: "16px", 
                      padding: "12px", 
                      "@media screen and (max-width: 767px)": {
                        width:'76px',
                        padding: "5px",
                        borderRadius: "10px",
                      },
                      "@media screen and (min-width: 768px) and (max-width: 1023px)": {
                        width:'90px',
                        padding: "5px",
                        borderRadius: "10px",
                      },
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      border: "none",
                      boxShadow: "none",
                      borderRadius: "16px",
                      boxShadow:'-1px 4px 99px #0000001a'
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "white",
                      color: "#101010",
                      opacity: "0.8",
                      fontSize: "1rem",
                      textAlign:'center',
                      fontFamily:'Poppins-Regular',
                      padding: "16px", 
                      borderBottom: state.isLastOption ? 'none' : '1px solid rgba(112, 112, 112, 0.2)'
                    }),
                    dropdownIndicator: (base) => ({
                      ...base,
                      color: "black",
                      opacity: "1",
                    }),
                    singleValue: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused ? "white" : "white",
                      border: 0,
                      color: "#101010",
                      fontSize: "1.25rem",
                      "@media screen and (max-width: 767px)": {
                        fontSize: "0.875rem", 
                      },
                    })
                  }}
                />
              </div>
              <input
                type="text"
                id="input4"
                className="Singup_input_guest_phoneNumber InputTextClass"
                name="phonenumber"
                onChange={handleInputChange}
                defaultValue={phoneNumber}
                placeholder="Enter Mobile Number"
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                required
              />
            </div>
            <div className="OnePAge_SignUp_permission_text">
                <p>By Joining You Agree To The{" "}
                  <span onClick={openModal} style={{ cursor: "pointer", color: "#10b2d1",textDecoration:'underline' }}>
                    Terms and Conditions
                  </span>
                </p>
                <Modal isOpen={isModalOpen} onRequestClose={closeModal} contentLabel="Terms and Conditions Modal" className="custom-modal-TermsAndCondition">
                  <div className="TermsAndCoditon_M2M">
                    <div className="TermsAndCodition_Container">
                        <div className="DeskTop_TermsBackButton">
                          <div className="TermsAndCodition_Popup_Heading">
                              <h1>Terms And Conditions</h1>
                              <p onClick={closeModal}>x</p>
                          </div>
                        </div>
                        <div className='TermsAndcondition_SubText' dangerouslySetInnerHTML={{ __html: TemsData,}}/>
                        <div className="Mobile_TermsBackButton">
                          <div className="BtnTemrsAndCondition">
                            <button onClick={closeModal}>Back</button>
                          </div>
                        </div>
                    </div>
                  </div>
                </Modal>
            </div>
            <div>
              <button className="Login_PAge_Button" onClick={handleGetOTPClick}> Generate OTP</button>
            </div>

            <div className="Singup_AgreeTerms_alerady">
              <p>Don't Have An Account? <a href="/signup">Sign Up</a></p>
            </div>
          </div>
        </div>
      )}

      {/* sigup section otherwise otp section will be show */}
      {loginnewSectionVisible && (
        <div className="OtpPAge_Content">
          <div className="OtpPAge_OtpDiv">
            <div className="OtpPAge_OtpDiv_heading">
              <h1>Verify Mobile Number</h1>
              <p>Please Enter The OTP Shared To XXXXXXX{phoneNumber.slice(-3)}</p>
            </div>
            <div className="OtpPage_Input">
              {[0, 1, 2, 3, 4, 5].map((index) => (
                <input
                  key={index}
                  id={`otpInputBox${index}`}
                  type="text"
                  className="otp_input_box_signup InputTextClass"
                  maxLength="1"
                  value={enteredOTP[index]}
                  onChange={(e) => handleOTPChange(index, e.target.value)}
                />
              ))}
            </div>
            <div className="Login_ResendOtp_Div">
                  {showResendText ? (
                       <div className="OtpPage_resend" onClick={handleResendOTP}>
                             <p>Resend OTP</p>
                       </div>
                   ) : (
                       <div className="OtpPage_resend">
                          <p>{resendTimer} sec</p>
                       </div>
                   )}
             </div>
            <div>
              <button className="Login_PAge_Button" onClick={handleOTPSubmission}>Log In</button>
            </div>
            <div className="OnePAge_SignUp_permission_text_wrongcase">
              <p>Wrong Mobile Number?{" "}
                <span>
                  <Link style={{color:'#10b2d1',fontSize:'1rem'}} to={"/login"} onClick={goToBack}>Edit</Link>
                </span>
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="Login_Footer">
        <FotterAll />
      </div>
      <Modal isOpen={isBrowserAlerts}  contentLabel="Example Modal" className="custom-modal-alert">
           <p>{isBrowserAlerts}</p>
           <div className="ModalButton"><button onClick={closeBrowserModal}>Ok</button></div>
        </Modal> 
      <Modal isOpen={isWrongNumberAlerts}  contentLabel="Example Modal" className="custom-modal-alert">
           <p>{isWrongNumberAlerts}</p>
           <div className="ModalButton"><button onClick={closeWrongNumberModal}>Ok</button></div>
        </Modal> 
    </div>
  );
};

export default Login;
