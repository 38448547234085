import React, { useRef, useState } from "react";
import "./Explore_SubTabsPage.css";
import MainNavbar from '../../Components/Navbar/MainNavbar';
import FotterAll from '../../Components/Footer/FotterAll';
import Explore_Tabs_Gallery from '../../Components/Explore_Components/Explore_Tabs_Gallery';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Explore_Comman_slider_Tabs from "../../Components/Explore_Components/Explore_Comman_slider_Tabs";
import Explore_location_Comman from "../../Components/Explore_Components/Explore_location_Comman";
import { useNavigate } from 'react-router-dom';
import Explore_Slider_Sightseeing from "../../Components/Explore_Components/Explore_Slider_Sightseeing";

const Explore_SubTabsPage_Sightseeing = () => {
    const navigate = useNavigate();


    const handleSubmitReverse = () => {
      navigate('/explore');
    };
  return (
    <div className='explore-comman-tabs-container'>
    <div className="navbarContainer-sticky Main-navbar-border">
      <MainNavbar />
    </div>
    <div className="explore-comman-tabs-heading-content">
         <div className="back-to-home-page">
                    <h1 onClick={handleSubmitReverse}>Explore Mandwa Port</h1>
                    <svg className="back-to-home-page-svg" xmlns="http://www.w3.org/2000/svg" width="12.705" height="21.229" viewBox="0 0 12.705 21.229">
                      <path id="Alt_Arrow_Down" data-name="Alt Arrow Down" d="M21.988,9l-8.494,9.084L5,9" transform="translate(-6.88 24.109) rotate(-90)" fill="none" stroke="#767c8d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                    </svg>
                   <h3>Murund Janjeera Fort</h3>
         </div>  
      <div className="explore-comman-tabs-heading">
        <h1>Murund Janjeera Fort</h1>
      </div>
      <div className="white-liner-gradient"></div>
    </div>

    <div className='explore-comman-gallery'>
      <Explore_Tabs_Gallery />
    </div>

    <div className='explore-comman-about-comp'>
        <div className='explore-comman-about-comp-div-1'>
          <h1>About Murud Janjeera Fort</h1>
          <div className="about-mandwa-heading-bottam-line" style={{margin:0}}>
                  <div className="section bottam-line-1"></div>
                  <div className="section bottam-line-2"></div>
                  <div className="section bottam-line-3"></div>
          </div>
        </div>
        <div className="explore-comman-about-comp-div-2">
          {/* <h5>5000 ₹</h5> */}
          {/* <h2>Starting cost per person</h2> */}
          <p>Murud Janjira fort is around 54 km from Alibaug and is spread in the area of around 22 acres. The fort was originally a wooden structure, which was later renovated by Sidi Sirul Khan in the 17th century. Fort is an architectural masterpiece. The fundamental passage has a stone cutting, delineating six elephants caught by a solitary tiger, an image of the courage of the Siddis. The fortification has 23 tremendous bastions around 30-40 ft high, still in place. Amongst them are the three noteworthy guns, 'Kalal Bangadi', 'Landa Kasam' and "Chavi" worked from five metals.</p>
              <button className="row-button-schedule-BookNow"  type="submit">Know More</button>
        </div>
    </div>


       <div className='explore-overview-sightsee'>
            <h1>Overview</h1>
            <div className="about-mandwa-heading-bottam-line" style={{paddingBottom:'25px'}}>
                      <div className="section bottam-line-1"></div>
                      <div className="section bottam-line-2"></div>
                      <div className="section bottam-line-3"></div>
            </div>
            <div className="explore-container">
                <div className="columns">
                    <div className="column">
                        {/* <h3>What to expect</h3> */}
                        <div className="explore-row">
                            <ul>
                                <li><span>Location :</span> Gondavali Village</li>
                                <li><span>Timings :</span> 9AM to 6PM</li>
                                <li><span>Entry Fees :</span> INR 5</li>
                                <li><span>How To Reach :</span> Reaching the Murud Janjira fort is easy. There are various state transport buses run throughout the day from Alibaug, Pune, and Mumbai and take you to the fort. There are local guides available who are very welcoming and friendly with tourists.</li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>

     <div className="loaction-full-page">
        <h1>Location</h1>
              <div className="about-mandwa-heading-bottam-line" style={{}}>
                      <div className="section bottam-line-1"></div>
                      <div className="section bottam-line-2"></div>
                      <div className="section bottam-line-3"></div>
              </div>
          {/* <Explore_location_Comman/> */}
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3776.8344936234703!2d72.87917241489708!3d18.805527787243708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTjCsDQ4JzE5LjkiTiA3MsKwNTInNTIuOSJF!5e0!3m2!1sen!2sin!4v1616837987847!5m2!1sen!2sin"  allowFullScreen="" loading="lazy"></iframe>
        </div>

    <div>
      <Explore_Slider_Sightseeing/>
    </div>

    <div>
       <FotterAll />
    </div>
  </div>
  )
}

export default Explore_SubTabsPage_Sightseeing