/* var myHeaders = new Headers();
myHeaders.append("Authorization", "Basic YmFoYW1hc3BhcmFkaXNlcmV0YWlsOjFKUkw1SDNXSlhPRE1U");
myHeaders.append("Content-Type", "application/json");

var captureUrl = 'https://m2m.kapturecrm.com/';

export const fetchTrips = async (rawData) => {
    try {
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: rawData,
            redirect: 'follow'
        };
      const response = await fetch(`${captureUrl}cruise-sailing-pricing-details`, requestOptions);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    } catch (error) {
      throw new Error('Failed to fetch data');
    }
}; */
var myHeaders = new Headers();
myHeaders.append(
  "Authorization",
  "Basic "+process.env.REACT_APP_CAPTURE_TOCKEN
);
myHeaders.append("Content-Type", "application/json");

/* var captureUrl = "https://m2m.kapturecrm.com/";
//var M2MApiUrl = "https://appapi.m2mferries.com/";
//var M2MApiUrl = "https://m2mapi.mayradigital.com/";
var M2MApiUrl = "https://m2mapi.m2mferries.com/";
//var M2MApiUrl = "http://local.m2mcms.com/"; */

var captureUrl = process.env.REACT_APP_VAR_CAPTURE_URL;
var M2MApiUrl = process.env.REACT_APP_VAR_M2MAPI_URL;
class UserDataService {
    
  requestOtp = async (rawData) => {
    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: rawData,
    redirect: 'follow'
    };
    try {
        const response = await fetch(
          `${M2MApiUrl}api/request-otp`,
          requestOptions
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      } catch (error) {
        throw new Error("Failed to fetch data");
      }
    /* fetch("http://local.m2mcms.com/api/request-otp", requestOptions)
    .then(response => response.text())
    .then(result => {
        return result;
    })
    .catch(error => console.log('error', error)); */
  };
  
  userLogin = async (rawData) => {
    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: rawData,
    redirect: 'follow'
    };

    try {
        const response = await fetch(
        `${M2MApiUrl}api/login`,
        requestOptions
    );
    if (!response.ok) {
        throw new Error("Network response was not ok");
    }
    return response.json();
    } catch (error) {
    throw new Error("Failed to fetch data");
    }
  };
  
  userRegister = async (rawData) => {
    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: rawData,
    redirect: 'follow'
    };

    try {
        const response = await fetch(
        `${M2MApiUrl}api/sign-up`,
        requestOptions
    );
    if (!response.ok) {
        throw new Error("Network response was not ok");
    }
    return response.json();
    } catch (error) {
    throw new Error("Failed to fetch data");
    }
  };
  
  updateProfile = async (rawData) => {
    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: rawData,
    redirect: 'follow'
    };

    try {
        const response = await fetch(
        `${M2MApiUrl}api/update-profile`,
        requestOptions
    );
    if (!response.ok) {
        throw new Error("Network response was not ok");
    }
    return response.json();
    } catch (error) {
    throw new Error("Failed to fetch data");
    }
  };
  
  getStateList = async () => {
    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    };
    try {
        const response = await fetch(
        `${M2MApiUrl}api/get-states`,
        requestOptions
    );
    if (!response.ok) {
        throw new Error("Network response was not ok");
    }
    return response.json();
    } catch (error) {
      throw new Error("Failed to fetch data");
    }
  };
  
  getCitiesList = async (rawData) => {
    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: rawData,
    redirect: 'follow'
    };

    try {
        const response = await fetch(
        `${M2MApiUrl}api/get-cities`,
        requestOptions
    );
    if (!response.ok) {
        throw new Error("Network response was not ok");
    }
    return response.json();
    } catch (error) {
    throw new Error("Failed to fetch data");
    }
  };
  
  contactFormSubmit = async (rawData) => {
    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: rawData,
    redirect: 'follow'
    };

    try {
        const response = await fetch(
        `${M2MApiUrl}api/contact-us-enquiry`,
        requestOptions
    );
    if (!response.ok) {
        throw new Error("Network response was not ok");
    }
    return response.json();
    } catch (error) {
    throw new Error("Failed to fetch data");
    }
  };


  getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("userData"));
  };
  
  getCurrentBookingData = () => {
    return JSON.parse(localStorage.getItem("formData"));
  };
}

export default new UserDataService();
