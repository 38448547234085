import React, { useState, useEffect } from "react";
import "./FaqPage.css";
import FotterAll from "../../Components/Footer/FotterAll";
import MainNavbar from "../../Components/Navbar/MainNavbar";
import StaticDataService from "../../services/staticData";
import Meta from '../../Components/Meta';

const FaqPage = () => {
  const [seoMeta, setSeoMeta] = useState(null);
  const [activeTab, setActiveTab] = useState("Booking");
  const [accordionState, setAccordionState] = useState({});
  const [openQuestionIndex, setOpenQuestionIndex] = useState(null);
  const [tabs, setTabs] = useState(null);
  const [accordionData1, setAccordionData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

   //FOR META TAGS
    useEffect(() => {
      StaticDataService.SeoMetaServices('FAQs')
      .then((response) => {
      
        if (response.status !== "error") {
          
          setSeoMeta(response.seoMeta[0]);
        
        } else {
          /* setError(response.message); */
        }
      })
      .catch((err) => {
        //setError(err.message);
      });
    }, []);


  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
    setAccordionState({});
    setOpenQuestionIndex(null);
  };

  const handleAccordionClick = (question) => {
    setAccordionState((prevState) => ({
      ...prevState,
      [activeTab]: prevState[activeTab] === question ? null : question,
    }));

    // setArrowRotation((prevState) => ({
    //   ...prevState,
    //   [activeTab]: prevState[activeTab] === question ? "" : "rotate90",
    // }));
    setOpenQuestionIndex((prevIndex) =>
      prevIndex === question ? null : question
    );
  };

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const booking_id = queryParameters.get("booking_id");
    const status = queryParameters.get("status");
    //console.log(booking_id, status)
    StaticDataService.fetchFaq()
      .then((response) => {
        if (response.status !== "error") {
          //console.log(response.data);
          const tabData = [];
          const tabAccordianData = [];
          let catFaq = [];

          response.data.forEach((element) => {
            var cattitle_obj = [];
            tabData.push(element.title);
            var cattitle = element.title;
            cattitle_obj[cattitle] = element.faqs;
            tabAccordianData.push(cattitle_obj);
          });
          setTabs(tabData);
          setAccordionData(tabAccordianData);
        } else {
          /* setError(response.message); */
        }
      })
      .catch((err) => {
        //setError(err.message);
        //setLoading(false);
      });
  }, []);

  if (accordionData1) {
    var accordionData = accordionData1.reduce(
      (a, b) => Object.assign(a, b),
      {}
    );
  }
  const showLoginButton = false;

  return (
    <div>
      {seoMeta ? (
        <Meta
          title={seoMeta.title}
          description={seoMeta.description}
          keywords={seoMeta.keywords}
          site_name = {seoMeta.site_name}
          canonicalUrl = {seoMeta.canonicalUrl}
          url={seoMeta.url}
          image = {seoMeta.image}
        />
      ):null}
      <div className="navbarContainer-sticky Main-navbar-border">
        <MainNavbar isHomepage={false} />
      </div>
      <div className="Faq_Section">
        <div className="Faq_Section_Heading">
          <h1>Frequently Asked Questions</h1>
          <p>We have put together some commonly asked questions to give information about travelling with M2M Ferries and what we do.</p>{" "}
        </div>
        <div className="Faq_WhiteLinearGradint"></div>
      </div>

      <div className="FaqMainContainer">
        <div className="FaqWarapIt">
          <div className="FaqTabs">
            {tabs &&
              tabs.map((tab) => (
                <div key={tab} className={`FaqSpeartetab ${activeTab} ${activeTab === tab ? "active" : ""}`} onClick={() => handleTabClick(tab)}>
                  {/* <img src={`/assets/img/Faq/${activeTab === tab ? "FaqWhite.png" : "FaqBlack.png"}`}alt="asad"/> */}
                  <h2> {tab}</h2>
                </div>
              ))}
          </div>
          <div className="FaqTab_Content">
            {accordionData &&
              accordionData[activeTab].map((item, index) => (
                <div key={index} className="Faqaccordion">
                  <div
                    className={`Faqquestion ${
                      accordionState[activeTab] === index ? "active" : ""
                    }`}
                    onClick={() => handleAccordionClick(index)}
                  >
                    <h3>
                      {item.question}
                      <span
                        className={`ArrowIcon ${
                          openQuestionIndex === index ? "rotate180" : ""
                        }`}
                      >
                        <img src="/assets/img/Widget/Arrow_Down.png" alt="down-arrow"
                          className="custom_dropdown__arrow"/>{" "}
                      </span>
                    </h3>
                    {/* Include the answer content directly */}
                    {accordionState[activeTab] === index && (
                      <div
                        className="Faqanswer"
                        dangerouslySetInnerHTML={{
                          __html: item.answer,
                        }}
                      />
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div>
        <FotterAll />
      </div>
    </div>
  );
};

export default FaqPage;
