/* var myHeaders = new Headers();
myHeaders.append("Authorization", "Basic YmFoYW1hc3BhcmFkaXNlcmV0YWlsOjFKUkw1SDNXSlhPRE1U");
myHeaders.append("Content-Type", "application/json");

var captureUrl = 'https://m2m.kapturecrm.com/';

export const fetchTrips = async (rawData) => {
    try {
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: rawData,
            redirect: 'follow'
        };
      const response = await fetch(`${captureUrl}cruise-sailing-pricing-details`, requestOptions);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    } catch (error) {
      throw new Error('Failed to fetch data');
    }
}; */
var myHeaders = new Headers();
myHeaders.append(
  "Authorization",
  "Basic "+process.env.REACT_APP_CAPTURE_TOCKEN
);
myHeaders.append("Content-Type", "application/json");

/* var captureUrl = "https://m2m.kapturecrm.com/";
var M2MApiUrl = "https://appapi.m2mferries.com/";
//var M2MApiUrl = "https://m2mapi.mayradigital.com/";
var M2MApiUrl = "https://m2mapi.m2mferries.com/";
//var M2MApiUrl = "http://local.m2mcms.com/"; */

var captureUrl = process.env.REACT_APP_VAR_CAPTURE_URL;
var M2MApiUrl = process.env.REACT_APP_VAR_M2MAPI_URL;

export { M2MApiUrl };

class StaticDataService {
    
  fetchFaq = async () => {
    /* var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: rawData,
    redirect: 'follow'
    }; */
    try {
        const response = await fetch(
          `${M2MApiUrl}api/faqs`,
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      } catch (error) {
        throw new Error("Failed to fetch data");
      }
  };

  StaticData = async () => {
    try {
        const response = await fetch(
          `${M2MApiUrl}api/static-data`,
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      } catch (error) {
        throw new Error("Failed to fetch data");
      }
  };
  
  carBrandsData = async () => {
    try {
        const response = await fetch(
          `${M2MApiUrl}car-brands.json`,
        );
        /* const response = await fetch(
          `https://appapi.m2mferries.com/car-brands.json`,
        ); */
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        return data;
        // console.log("Data fetched successfully:", data);
      } catch (error) {
        console.error("Error fetching data:", error);
        throw new Error("Failed to fetch data");
      }
  };
  
  ServiceInfo = async () => {
    try {
        const response = await fetch(
          `${M2MApiUrl}api/service-info-data`,
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      } catch (error) {
        throw new Error("Failed to fetch data");
      }
  };

  HomePartnersBanners = async () => {
    try {
        const response = await fetch(
          `${M2MApiUrl}api/home-page-partners-banners`,
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      } catch (error) {
        throw new Error("Failed to fetch data");
      }
  };
  
  HomeMainBanners = async () => {
    try {
        const response = await fetch(
          `${M2MApiUrl}api/home-page-banners`,
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      } catch (error) {
        throw new Error("Failed to fetch data");
      }
  };


  SeoMetaServices = async (pagename) => {
    try {
        const response = await fetch(
          `${M2MApiUrl}api/seo-meta/${pagename}`,
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      } catch (error) {
        throw new Error("Failed to fetch data");
      }
  };

    exploreService = async () => {
    try {
        const response = await fetch(
          `${M2MApiUrl}api/get-explore-categories/1`,
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      } catch (error) {
        throw new Error("Failed to fetch data");
      }
  };

    exploreServiceSubPages = async ($slug) => {
    try {
        const response = await fetch(
          `${M2MApiUrl}api/explore-post-detail/${$slug}`,
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      } catch (error) {
        throw new Error("Failed to fetch data");
      }
  };

  getExplorePosts = async (category) => {
    try {
        const response = await fetch(
          `${M2MApiUrl}api/get-explore-posts/${category}`,
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        // console.log("response : ",response);
        return response.json();
      } catch (error) {
        throw new Error("Failed to fetch data");
      }
  };
}


export default new StaticDataService();
