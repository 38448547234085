import "./App.css";
// import { NavbarProvider } from "./Context/NavbarContext";
import AllRoutes from "./Routes/AllRoutes";
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import Clarity from '@microsoft/clarity';

const tagManagerArgs = {
  //gtmId: 'GTM-KQRG8PJ', // Replace with your actual GTM ID
  gtmId: 'GTM-N9SRFMX', // Replace with your actual GTM ID
};

function App() {
  const spinner = document.getElementById('spinner');

  useEffect(() => {
    if(process.env.REACT_APP_ENV === "production"){
      TagManager.initialize(tagManagerArgs);
      const projectId = "oxsk8ur3px";

      Clarity.init(projectId);
    }
  }, []);

  if (spinner && !spinner.hasAttribute('hidden')) {
    spinner.setAttribute('hidden', 'true');
  }
  return (
    <div className="App">
      {" "}
      <AllRoutes />
    </div>
  );
}

// Custom component to track route changes
const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        page: location.pathname,
      },
    });
  }, [location]);

  return null;
};

export default App;
