import React, { useEffect, useRef, useState } from "react";
import "./ThankYouPage.css";
import FotterAll from "../../Components/Footer/FotterAll";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Parents_thank from "../../Components/OnwordBooking_Comp/CommanComp/Parents_thank";
import { Link } from "react-router-dom";
import MainNavbar from "../../Components/Navbar/MainNavbar";

const ThankYouPage = () => {
  const [showTick, setShowTick] = useState(false);

  useEffect(() => {
    const startAnimation = () => {
      setShowTick(false);
      const loader = document.getElementById("loader");
      loader.style.background = "transparent";
      setTimeout(() => {
        setShowTick(true);
        loader.style.background = "white";
      }, 3000);
    };
    startAnimation();
    const intervalId = setInterval(startAnimation, 6000);
    return () => clearInterval(intervalId);
  }, []);

  //   explore mandwa

  const [currentPage, setCurrentPage] = useState(1);
  const sliderRef = useRef();
  const data = [
    {
      image: "/assets/img/Explore/abc.png",
      heading: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit,",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam hendrerit nisi sed sollicitudin pellentesque. Nunc posuere purus rhoncus pulvinar aliquam. Ut aliquet tristique ",
    },
    {
      image: "/assets/img/Explore/abc.png",
      heading: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit,",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam hendrerit nisi sed sollicitudin pellentesque. Nunc posuere purus rhoncus pulvinar aliquam. Ut aliquet tristique ",
    },
    {
      image: "/assets/img/Explore/abc.png",
      heading: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit,",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam hendrerit nisi sed sollicitudin pellentesque. Nunc posuere purus rhoncus pulvinar aliquam. Ut aliquet tristique ",
    },
    {
      image: "/assets/img/Explore/abc.png",
      heading: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit,",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam hendrerit nisi sed sollicitudin pellentesque. Nunc posuere purus rhoncus pulvinar aliquam. Ut aliquet tristique ",
    },
    {
      image: "/assets/img/Explore/abc.png",
      heading: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit,",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam hendrerit nisi sed sollicitudin pellentesque. Nunc posuere purus rhoncus pulvinar aliquam. Ut aliquet tristique ",
    },
    {
      image: "/assets/img/Explore/abc.png",
      heading: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit,",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam hendrerit nisi sed sollicitudin pellentesque. Nunc posuere purus rhoncus pulvinar aliquam. Ut aliquet tristique ",
    },

    // Add more data objects as needed
  ];
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (currentSlide) => {
      setCurrentPage(Math.ceil((currentSlide + 1) / 3));
    },
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const totalPage = Math.ceil(data.length / 3);

  const goToNext = () => {
    sliderRef.current.slickNext();
  };

  const goToPrev = () => {
    sliderRef.current.slickPrev();
  };

  //   our parents

  return (
    <div>
      <div className="ThankYouPageNavbar_Sticky">
        <MainNavbar isHomepage={false} />
      </div>
      <div className="ThankYouMainContainer">
        <div className="ThankYou_HomeHeading" style={{ cursor: "pointer" }}>
          <Link to={"/"}>
            <h1>Back To Home</h1>
          </Link>
        </div>
        {/* round checkbox start */}

        <div className="TahnYouPAge_Continer">
          <div className="TahnYouPAge_Continer_Div1">
            <div className="Div1_roundRightTick">
              <div className="loader-container">
                <div
                  className="loader"
                  id="loader"
                  style={{ background: "white" }}
                ></div>
                {showTick && (
                  <div className="tick" id="tick">
                    &#10003;
                  </div>
                )}
              </div>
            </div>
            <div className="Div1_Tahnkyou_heading">
              <h1>Your Booking Is Confirmed!</h1>
            </div>
          </div>
          <div className="TahnYouPAge_Continer_Div2">
            <h1>Booking ID : 37530067</h1>
            <p>
              See You Soon! Thank you for choosing M2M Ferries! The booking
              confirmation has been sent to your email. For any further
              assistance, kindly email us at{" "}
              <a href=""> support@m2mferries.com</a> or call +918291902662.
            </p>
            <div className="TahnYouPAge_Continer_Div2_buttons">
              <div className="thankbtn1">
                <button className="ThankYou_Button" type="button">
                  View on app
                </button>
              </div>
              <div className="thankbtn2">
                <button className="ThankYou_Button" type="button">
                  Download ticket
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* mapsData */}
        <div className="Map_ThankYou_Section">
          <div className="Map_ThankYou_Section_heading">
            <h1>How To Get There ?</h1>
          </div>
          <div className="Map_ThankYou_Section_TwoDiv">
            <div className="Map_ThankYou_Section_TwoDiv1">
              <img src="/assets/img/BookingFlow/Map1.png" alt="abcss" />
              <div className="heading_thankYou">
                <h1>M2m Mumbai Terminal </h1>
                <p>Get Direction</p>
              </div>
              <div className="heading_thankYou_pTag">
                <p>
                  M2m Mumbai Terminal Ferry Wharf, Bhaucha Dhakka Mumbai Port,
                  Mumbai - 400010, Maharashtra
                </p>
              </div>
            </div>
            <div className="Map_ThankYou_Section_TwoDiv1">
              <img src="/assets/img/BookingFlow/Map2.png" alt="abcss" />
              <div className="heading_thankYou">
                <h1>M2m Mumbai Terminal </h1>
                <p>Get Direction</p>
              </div>
              <div className="heading_thankYou_pTag">
                <p>
                  M2m Mumbai Terminal Ferry Wharf, Bhaucha Dhakka Mumbai Port,
                  Mumbai - 400010, Maharashtra
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* explore Mandwa */}
        <div className="mandwa_SectionThankyouPage">
          <div className="mandwa_SectionThankyouPage_heading">
            <h1>Explore Mandwa</h1>
          </div>

          <div className="slider_container">
            <Slider ref={sliderRef} {...settings}>
              {data.map((item, index) => (
                <div key={index} className="slides">
                  <div className="border-radius">
                    <img src={item.image} alt={item.heading} />
                  </div>
                  <h2>{item.heading}</h2>
                  <p>{item.description}</p>
                </div>
              ))}
            </Slider>
            <div className="pagination_Explore">
              <div className="prev_button_eight" onClick={goToPrev} disabled={currentPage === 1}>
                <img src="/assets/img/Partners/arrow.png" alt="dcs" />
              </div>
              <div className="page_indicator">{currentPage}/{totalPage}</div>
              <div className="next_button_eight" onClick={goToNext} disabled={currentPage === totalPage}>
                <img src="/assets/img/Partners/arrow.png" alt="dcs" />
              </div>
            </div>
          </div>
        </div>

        {/* our parents */}
        <div className="Our_Partners_thankyouPage">
          <Parents_thank />
        </div>
      </div>
      <div>
        <FotterAll />
      </div>
    </div>
  );
};

export default ThankYouPage;
