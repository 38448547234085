import React from "react";
// import SliderImag from "../../Components/OnwordBooking_Comp/CommanComp/SliderImag";
import FotterAll from "../../Components/Footer/FotterAll";
import VehicleFlow from "../../Components/OnwordBooking_Comp/VehicleFlow";
import MainNavbar from "../../Components/Navbar/MainNavbar";

const Vehicle = () => {
  return (
    <div className="VehiclePage">
      <div style={{ position: "sticky", top: 0, zIndex: 100, borderBottom: "1px solid rgba(33, 145, 208, 1)" }}>
      <MainNavbar isHomepage={false} />
      </div>
      {/* <SliderImag /> */}
      <div className="content_VehiclePage">
        <VehicleFlow />
      </div>
      <FotterAll />
    </div>
  );
};

export default Vehicle;
