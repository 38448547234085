import React, { useRef, useState } from "react";
import "./Explore_Comman_slider_Tabs.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Explore_Slider_Sightseeing = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const sliderRef = useRef();

  // This is the data you want to render dynamically
  const data = [
    {
      heading: "Kolaba Fort",
      image: "/assets/img/explore-page/explore-comman-content-5.png",
      description: "European, Italian, Chinese",
      price: {
        amount: "₹1500",
        text: "for 2 people",
      },
      address: "Gondhalpada, Varasoli, Alibaug, 402209",
      rating: "4.1",
    },
    {
      heading: "Murud Janjeera Fort",
           image: "/assets/img/explore-page/explore-comman-content-5.png",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam hendrerit nisi sed sollicitudin pellentesque.",
      price: {
        amount: "₹1200",
        text: "for 2 people",
      },
      address: "Some Location, Alibaug",
      rating: "4.5",
    },
    {
      heading: "Kolaba Fort",
      image: "/assets/img/explore-page/explore-comman-content-5.png",
      description: "European, Italian, Chinese",
      price: {
        amount: "₹1500",
        text: "for 2 people",
      },
      address: "Gondhalpada, Varasoli, Alibaug, 402209",
      rating: "4.1",
    },
    {
      heading: "Murud Janjeera Fort",
           image: "/assets/img/explore-page/explore-comman-content-5.png",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam hendrerit nisi sed sollicitudin pellentesque.",
      price: {
        amount: "₹1200",
        text: "for 2 people",
      },
      address: "Some Location, Alibaug",
      rating: "4.5",
    },
    {
      heading: "Kolaba Fort",
      image: "/assets/img/explore-page/explore-comman-content-5.png",
      description: "European, Italian, Chinese",
      price: {
        amount: "₹1500",
        text: "for 2 people",
      },
      address: "Gondhalpada, Varasoli, Alibaug, 402209",
      rating: "4.1",
    },
    {
      heading: "Murud Janjeera Fort",
           image: "/assets/img/explore-page/explore-comman-content-5.png",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam hendrerit nisi sed sollicitudin pellentesque.",
      price: {
        amount: "₹1200",
        text: "for 2 people",
      },
      address: "Some Location, Alibaug",
      rating: "4.5",
    },
    {
      heading: "Kolaba Fort",
      image: "/assets/img/explore-page/explore-comman-content-5.png",
      description: "European, Italian, Chinese",
      price: {
        amount: "₹1500",
        text: "for 2 people",
      },
      address: "Gondhalpada, Varasoli, Alibaug, 402209",
      rating: "4.1",
    },
    {
      heading: "Murud Janjeera Fort",
           image: "/assets/img/explore-page/explore-comman-content-5.png",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam hendrerit nisi sed sollicitudin pellentesque.",
      price: {
        amount: "₹1200",
        text: "for 2 people",
      },
      address: "Some Location, Alibaug",
      rating: "4.5",
    },
    // Add more objects if needed
  ];

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (currentSlide) => {
      setCurrentPage(currentSlide + 1);
    },
    responsive: [
      {
        breakpoint: 968,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const totalPage = data.length;

  const goToNext = () => {
    sliderRef.current.slickNext();
  };

  const goToPrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <div className="explore-comman-slider-section">
      <h1>About Surfing At Mandwa Beach</h1>
      <div className="about-mandwa-heading-bottam-line">
        <div className="section bottam-line-1"></div>
        <div className="section bottam-line-2"></div>
        <div className="section bottam-line-3"></div>
      </div>

      <div className="slider_container">
        <div className="explore-comman-slider-desktop">
          <Slider ref={sliderRef} {...settings}>
            {data.map((item, index) => (
              <div key={index} className="slides">
                <div className="borderRadiusZoomIN">
                  <img src={item.image} alt={item.heading} />
                </div>
                <div className="information-comman-slider">
                  <div className="information-comman-slider-div-1">
                    <h1>{item.heading}</h1>
                    {/* <p>
                      {item.price.amount} <span>{item.price.text}</span>
                    </p> */}
                    <div className="information-comman-slider-div-2-rating-div-2">
                      <h2>{item.rating}</h2>
                      <div className="rating-star-svg">
                        <svg xmlns="http://www.w3.org/2000/svg" width="23.987" height="23.231" viewBox="0 0 23.987 23.231">
                          <path id="Star" d="M1.327,12.4,4.887,15,3.535,19.187a3.228,3.228,0,0,0,4.984,3.594L12,20.219l3.482,2.559a3.227,3.227,0,0,0,4.983-3.591L19.113,15l3.56-2.6a3.227,3.227,0,0,0-1.9-5.832H16.4L15.073,2.432a3.227,3.227,0,0,0-6.146,0L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832Z" fill="#eeda55"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="23.987" height="23.231" viewBox="0 0 23.987 23.231">
                          <path id="Star" d="M1.327,12.4,4.887,15,3.535,19.187a3.228,3.228,0,0,0,4.984,3.594L12,20.219l3.482,2.559a3.227,3.227,0,0,0,4.983-3.591L19.113,15l3.56-2.6a3.227,3.227,0,0,0-1.9-5.832H16.4L15.073,2.432a3.227,3.227,0,0,0-6.146,0L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832Z" fill="#eeda55"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="23.987" height="23.231" viewBox="0 0 23.987 23.231">
                          <path id="Star" d="M1.327,12.4,4.887,15,3.535,19.187a3.228,3.228,0,0,0,4.984,3.594L12,20.219l3.482,2.559a3.227,3.227,0,0,0,4.983-3.591L19.113,15l3.56-2.6a3.227,3.227,0,0,0-1.9-5.832H16.4L15.073,2.432a3.227,3.227,0,0,0-6.146,0L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832Z" fill="#eeda55"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="23.987" height="23.231" viewBox="0 0 23.987 23.231">
                          <path id="Star" d="M1.327,12.4,4.887,15,3.535,19.187a3.228,3.228,0,0,0,4.984,3.594L12,20.219l3.482,2.559a3.227,3.227,0,0,0,4.983-3.591L19.113,15l3.56-2.6a3.227,3.227,0,0,0-1.9-5.832H16.4L15.073,2.432a3.227,3.227,0,0,0-6.146,0L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832Z" fill="#eeda55"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="information-comman-slider-div-2">
                    {/* <div className="information-comman-slider-div-2-rating-div-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="17.471" height="20.862" viewBox="0 0 17.471 20.862">
                        <g id="_01_align_center" transform="translate(-40.725 -0.198)">
                          <path id="Path_190680" d="M49.46,21.06l-.606-.519c-.835-.7-8.129-7-8.129-11.607a8.735,8.735,0,1,1,17.471,0c0,4.608-7.294,10.907-8.126,11.61Zm0-18.973a6.854,6.854,0,0,0-6.847,6.847c0,2.895,4.488,7.519,6.847,9.636,2.359-2.117,6.846-6.744,6.846-9.636A6.854,6.854,0,0,0,49.46,2.087Z" fill="#767c8d"/>
                          <path id="Path_190681" d="M173.589,136.524a3.463,3.463,0,1,1,3.463-3.463A3.463,3.463,0,0,1,173.589,136.524Zm0-5.194a1.731,1.731,0,1,0,1.731,1.731A1.731,1.731,0,0,0,173.589,131.33Z" transform="translate(-124.128 -124.128)" fill="#767c8d"/>
                        </g>
                      </svg>
                      <h1>{item.address}</h1>
                    </div> */}
                    {/* <div className="information-comman-slider-div-2-rating-div-2">
                      <h2>{item.rating}</h2>
                      <div className="rating-star-svg">
                        <svg xmlns="http://www.w3.org/2000/svg" width="23.987" height="23.231" viewBox="0 0 23.987 23.231">
                          <path id="Star" d="M1.327,12.4,4.887,15,3.535,19.187a3.228,3.228,0,0,0,4.984,3.594L12,20.219l3.482,2.559a3.227,3.227,0,0,0,4.983-3.591L19.113,15l3.56-2.6a3.227,3.227,0,0,0-1.9-5.832H16.4L15.073,2.432a3.227,3.227,0,0,0-6.146,0L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832Z" fill="#eeda55"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="23.987" height="23.231" viewBox="0 0 23.987 23.231">
                          <path id="Star" d="M1.327,12.4,4.887,15,3.535,19.187a3.228,3.228,0,0,0,4.984,3.594L12,20.219l3.482,2.559a3.227,3.227,0,0,0,4.983-3.591L19.113,15l3.56-2.6a3.227,3.227,0,0,0-1.9-5.832H16.4L15.073,2.432a3.227,3.227,0,0,0-6.146,0L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832Z" fill="#eeda55"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="23.987" height="23.231" viewBox="0 0 23.987 23.231">
                          <path id="Star" d="M1.327,12.4,4.887,15,3.535,19.187a3.228,3.228,0,0,0,4.984,3.594L12,20.219l3.482,2.559a3.227,3.227,0,0,0,4.983-3.591L19.113,15l3.56-2.6a3.227,3.227,0,0,0-1.9-5.832H16.4L15.073,2.432a3.227,3.227,0,0,0-6.146,0L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832Z" fill="#eeda55"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="23.987" height="23.231" viewBox="0 0 23.987 23.231">
                          <path id="Star" d="M1.327,12.4,4.887,15,3.535,19.187a3.228,3.228,0,0,0,4.984,3.594L12,20.219l3.482,2.559a3.227,3.227,0,0,0,4.983-3.591L19.113,15l3.56-2.6a3.227,3.227,0,0,0-1.9-5.832H16.4L15.073,2.432a3.227,3.227,0,0,0-6.146,0L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832Z" fill="#eeda55"/>
                        </svg>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="explore-comman-slider-mobile">
          <Slider ref={sliderRef} {...settings}>
            {data.reduce((resultArray, item, index, array) => {
              if (index % 2 === 0) {
                resultArray.push(array.slice(index, index + 2));
              }
              return resultArray;
            }, []).map((pair, index) => (
              <div key={index} className='slides'>
                {pair.map((item, subIndex) => (
                  <div
                    key={subIndex}
                    className='top-attration-main-div-content'
                  >
                    <div className="borderRadiusZoomIN">
                    <img src={item.image} alt={item.heading} />
                  </div>
                  <div className="information-comman-slider">
                    <div className="information-comman-slider-div-1">
                      <h1>{item.heading}</h1>
                      {/* <p>
                        {item.price.amount} <span>{item.price.text}</span>
                      </p> */}
                        <div className="information-comman-slider-div-2-rating-div-2">
                        <h2>{item.rating}</h2>
                        <div className="rating-star-svg">
                          <svg xmlns="http://www.w3.org/2000/svg" width="23.987" height="23.231" viewBox="0 0 23.987 23.231">
                            <path id="Star" d="M1.327,12.4,4.887,15,3.535,19.187a3.228,3.228,0,0,0,4.984,3.594L12,20.219l3.482,2.559a3.227,3.227,0,0,0,4.983-3.591L19.113,15l3.56-2.6a3.227,3.227,0,0,0-1.9-5.832H16.4L15.073,2.432a3.227,3.227,0,0,0-6.146,0L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832Z" fill="#eeda55"/>
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="23.987" height="23.231" viewBox="0 0 23.987 23.231">
                            <path id="Star" d="M1.327,12.4,4.887,15,3.535,19.187a3.228,3.228,0,0,0,4.984,3.594L12,20.219l3.482,2.559a3.227,3.227,0,0,0,4.983-3.591L19.113,15l3.56-2.6a3.227,3.227,0,0,0-1.9-5.832H16.4L15.073,2.432a3.227,3.227,0,0,0-6.146,0L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832Z" fill="#eeda55"/>
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="23.987" height="23.231" viewBox="0 0 23.987 23.231">
                            <path id="Star" d="M1.327,12.4,4.887,15,3.535,19.187a3.228,3.228,0,0,0,4.984,3.594L12,20.219l3.482,2.559a3.227,3.227,0,0,0,4.983-3.591L19.113,15l3.56-2.6a3.227,3.227,0,0,0-1.9-5.832H16.4L15.073,2.432a3.227,3.227,0,0,0-6.146,0L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832Z" fill="#eeda55"/>
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="23.987" height="23.231" viewBox="0 0 23.987 23.231">
                            <path id="Star" d="M1.327,12.4,4.887,15,3.535,19.187a3.228,3.228,0,0,0,4.984,3.594L12,20.219l3.482,2.559a3.227,3.227,0,0,0,4.983-3.591L19.113,15l3.56-2.6a3.227,3.227,0,0,0-1.9-5.832H16.4L15.073,2.432a3.227,3.227,0,0,0-6.146,0L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832Z" fill="#eeda55"/>
                          </svg>
                        </div>
                      </div>
                    </div>
                    {/* <div className="information-comman-slider-div-2">
                      <div className="information-comman-slider-div-2-rating-div-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17.471" height="20.862" viewBox="0 0 17.471 20.862">
                          <g id="_01_align_center" transform="translate(-40.725 -0.198)">
                            <path id="Path_190680" d="M49.46,21.06l-.606-.519c-.835-.7-8.129-7-8.129-11.607a8.735,8.735,0,1,1,17.471,0c0,4.608-7.294,10.907-8.126,11.61Zm0-18.973a6.854,6.854,0,0,0-6.847,6.847c0,2.895,4.488,7.519,6.847,9.636,2.359-2.117,6.846-6.744,6.846-9.636A6.854,6.854,0,0,0,49.46,2.087Z" fill="#767c8d"/>
                            <path id="Path_190681" d="M173.589,136.524a3.463,3.463,0,1,1,3.463-3.463A3.463,3.463,0,0,1,173.589,136.524Zm0-5.194a1.731,1.731,0,1,0,1.731,1.731A1.731,1.731,0,0,0,173.589,131.33Z" transform="translate(-124.128 -124.128)" fill="#767c8d"/>
                          </g>
                        </svg>
                        <h1>{item.address}</h1>
                      </div>
                      <div className="information-comman-slider-div-2-rating-div-2">
                        <h2>{item.rating}</h2>
                        <div className="rating-star-svg">
                          <svg xmlns="http://www.w3.org/2000/svg" width="23.987" height="23.231" viewBox="0 0 23.987 23.231">
                            <path id="Star" d="M1.327,12.4,4.887,15,3.535,19.187a3.228,3.228,0,0,0,4.984,3.594L12,20.219l3.482,2.559a3.227,3.227,0,0,0,4.983-3.591L19.113,15l3.56-2.6a3.227,3.227,0,0,0-1.9-5.832H16.4L15.073,2.432a3.227,3.227,0,0,0-6.146,0L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832Z" fill="#eeda55"/>
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="23.987" height="23.231" viewBox="0 0 23.987 23.231">
                            <path id="Star" d="M1.327,12.4,4.887,15,3.535,19.187a3.228,3.228,0,0,0,4.984,3.594L12,20.219l3.482,2.559a3.227,3.227,0,0,0,4.983-3.591L19.113,15l3.56-2.6a3.227,3.227,0,0,0-1.9-5.832H16.4L15.073,2.432a3.227,3.227,0,0,0-6.146,0L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832Z" fill="#eeda55"/>
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="23.987" height="23.231" viewBox="0 0 23.987 23.231">
                            <path id="Star" d="M1.327,12.4,4.887,15,3.535,19.187a3.228,3.228,0,0,0,4.984,3.594L12,20.219l3.482,2.559a3.227,3.227,0,0,0,4.983-3.591L19.113,15l3.56-2.6a3.227,3.227,0,0,0-1.9-5.832H16.4L15.073,2.432a3.227,3.227,0,0,0-6.146,0L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832Z" fill="#eeda55"/>
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="23.987" height="23.231" viewBox="0 0 23.987 23.231">
                            <path id="Star" d="M1.327,12.4,4.887,15,3.535,19.187a3.228,3.228,0,0,0,4.984,3.594L12,20.219l3.482,2.559a3.227,3.227,0,0,0,4.983-3.591L19.113,15l3.56-2.6a3.227,3.227,0,0,0-1.9-5.832H16.4L15.073,2.432a3.227,3.227,0,0,0-6.146,0L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832Z" fill="#eeda55"/>
                          </svg>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  </div>
                ))}
              </div>
            ))}
          </Slider>
        </div>
        <div className="pagination_Explore_about">
          <div
            className={`prev_button_eight_about ${
              currentPage === 1 ? "disabled" : ""
            }`}
            onClick={goToPrev}
          >
            <img src="/assets/img/Partners/arrow.png" alt="prev" />
          </div>

          <div className="page_indicator_about">
            {currentPage}/{totalPage}
          </div>

          <div
            className={`next_button_eight_about ${
              currentPage === totalPage ? "disabled" : ""
            }`}
            onClick={goToNext}
          >
            <img src="/assets/img/Partners/arrow.png" alt="next" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Explore_Slider_Sightseeing;
