import { useEffect, useState } from "react";
import dayjs from "dayjs";
import locale from "dayjs/locale/en";
import weekdayPlugin from "dayjs/plugin/weekday";
import objectPlugin from "dayjs/plugin/toObject";
import isTodayPlugin from "dayjs/plugin/isToday";
import isSameOrBeforePlugin from "dayjs/plugin/isSameOrBefore";
import moment from "moment";

const Calendar = ({ highlightedDates, onDateClick }) => {
	const now = dayjs().locale({
		...locale,
	});
	dayjs.extend(weekdayPlugin);
	dayjs.extend(objectPlugin);
	dayjs.extend(isTodayPlugin);
	dayjs.extend(isSameOrBeforePlugin);
    
	const [currentMonth, setCurrentMonth] = useState(now);
	const [arrayOfDays, setArrayOfDays] = useState([]);
	const nextMonth = () => {
		const plus = currentMonth.add(1, "month");

		setCurrentMonth(plus);
	};

	const prevMonth = () => {
		const minus = currentMonth.subtract(1, "month");

		setCurrentMonth(minus);
	};

    const isCustomDate = (date) => {
    // Check if the date matches any date in the highlightedDates array
        return highlightedDates.some(highlightedDate => dayjs(highlightedDate).isSame(date, 'day'));
    };
    
    const handleDateClick = (date) => {
        if (onDateClick) {
            onDateClick(date);
        }
    // Check if the date matches any date in the highlightedDates array
        //return calEvents.some(highlightedDate => dayjs(highlightedDate).isSame(date, 'day'));
    };

	const renderHeader = () => {
		const dateFormat = "MMMM, YYYY";

		return (
			<div className="header row flex-middle">
				<div className="MainContiner_calender">
					<div className="NextAndPrev_Button">
						
					   <div className="Calender_Navgaitor">
							<div className="col col-start">
							   <div className="icon" onClick={() => prevMonth()}>chevron_left</div>
							</div>
							<div className="col col-center">{currentMonth.format(dateFormat)}</div>
							<div className="col col-end" onClick={() => nextMonth()}>
								<div className="icon">chevron_right</div>
							</div>
					   </div>

					  {/* <div className="lable-wrapper-Calender">
						<div className="lablel-Calender">
							<div className="square-box-Calender"></div>
							<h5>Schedule Available</h5>
						</div>
					</div> */}
					</div>
					
				</div>
			
			</div>
		);
	};

	const renderDays = () => {
		const dateFormat = "ddd";
		const days = [];

		for (let i = 0; i < 7; i++) {
			days.push(
				<div className="col col-centerr" key={i}>
					{now.weekday(i).format(dateFormat)}
				</div>
			);
		}
		return <div className="days row">{days}</div>;
	};

	const getAllDays = () => {
		let currentDate = currentMonth.startOf("month").weekday(0);
		const nextMonth = currentMonth.add(1, "month").month();

		let allDates = [];
		let weekDates = [];

		let weekCounter = 1;
		while (currentDate.weekday(0).toObject().months !== nextMonth) {
            
            var formated = formateDateObject(currentDate);
            
			weekDates.push(formated);

			if (weekCounter === 7) {
				allDates.push({ dates: weekDates });
				weekDates = [];
				weekCounter = 0;
			}

			weekCounter++;
			currentDate = currentDate.add(1, "day");
		}

		setArrayOfDays(allDates);
	};

	useEffect(() => {
        if(highlightedDates) {
            
		    getAllDays();
        }
	}, [currentMonth, highlightedDates]);
	const renderCells = () => {
		const rows = [];
		let days = [];
		arrayOfDays.forEach((week, index) => {
			week.dates.forEach((d, i) => {
				days.push(
					<div
						className={`col cell ${
							d.isPastDay ? "disabled" : d.isCurrentDay ? "todays-day" : ""
						} ${d.custom_class} ${i == 6 ? "last-col": ""} ${i == 0 ? "first-col": ""} ${i == 0 ? "first-col": ""} ${d.isCurrentMonth ? "current-month": "outside-current-month"}`}
						key={i}
                        onClick={() => handleDateClick(d.date)}
					>
						<span className="number">{d.day}</span>
						<span className="bg">{d.day}</span>
					</div>
				);
			});
            //console.log(rows.length, index)
			rows.push(
				<div className={`row ${rows.length == (arrayOfDays.length -1) ? "last-row": ""}`} key={index}>
					{days}
				</div>
			);
			days = [];
		});

		return <div className="body">{rows}</div>;
	};
	const formateDateObject = date => {
		const today = dayjs();
		const clonedObject = { ...date.toObject() };
        var custom_class = '';
        if (isCustomDate(date)) {
            custom_class = 'highlighted-date';
        }
		else{
			custom_class = 'disabled';
		}
		
		const formatedObject = {
			day: clonedObject.date,
			month: clonedObject.months,
			year: clonedObject.years,
            custom_class: custom_class,
            date: date,
			isCurrentMonth: clonedObject.months === currentMonth.month(),
			isCurrentDay: date.isToday(),
			isPastDay: date.isBefore(today, 'day'),
		};

		return formatedObject;
	};

	return (
		<div className="calendar">
			{renderHeader()}
			{renderDays()}
			{renderCells()}
		</div>
	);
};

export default Calendar;

/* import React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/en'; // Import desired locale

const CustomCalendar = ({ highlightedDates }) => {
  // Function to check if a date should have a custom class
  const isCustomDate = (date) => {
    // Check if the date matches any date in the highlightedDates array
    return highlightedDates.some(highlightedDate => dayjs(highlightedDate).isSame(date, 'day'));
  };

  // Generate the calendar grid
  const generateCalendar = () => {
    const startDate = dayjs().startOf('month').startOf('week');
    const endDate = dayjs().endOf('month').endOf('week');

    const rows = [];
    let currentDate = startDate;

    while (currentDate.isBefore(endDate)) {
      const days = [];
      for (let i = 0; i < 7; i++) {
        const classes = ['calendar-day'];
        if (currentDate.month() !== dayjs().month()) {
          classes.push('other-month');
        }
        if (isCustomDate(currentDate)) {
          classes.push('highlighted-date');
        }
        days.push(
          <div key={currentDate} className={classes.join(' ')}>
            {currentDate.format('D')}
          </div>
        );
        currentDate = currentDate.add(1, 'day');
      }
      rows.push(<div key={currentDate} className="calendar-row">{days}</div>);
    }

    return rows;
  };

  return (
    <div className="calendar">
      {generateCalendar()}
    </div>
  );
};

export default CustomCalendar; */