import React, { useEffect, useState } from "react";
import FotterAll from "../../Components/Footer/FotterAll";
import MainNavbar from "../../Components/Navbar/MainNavbar";
import UserDataService from "../../services/userData";
import "./ContactForm.css";
import Meta from '../../Components/Meta';
import StaticDataService from "../../services/staticData";
import { FaSpinner } from "react-icons/fa";

const ContactForm = () => {
  const [seoMeta, setSeoMeta] = useState(null);

  //FOR META TAGS
  useEffect(() => {
    StaticDataService.SeoMetaServices('ContactUs')
    .then((response) => {
    
      if (response.status !== "error") {
        
        setSeoMeta(response.seoMeta[0]);
      
      } else {
        /* setError(response.message); */
      }
    })
    .catch((err) => {
      //setError(err.message);
    });
  }, []);
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  // Form Validation
  const [loading,setLoading] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    eventDetails: ""
  });
  const [errors, setErrors] = useState({});
  const [submisionMessage, setSubmisionMessage] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    validateForm();
  };

  const validateForm = () => {
    let errors = {};
    if (!formData.fullName.trim()) {
      errors.fullName = "Full Name is required";
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid";
    }
    if (!formData.phoneNumber.trim()) {
      errors.phoneNumber = "Phone Number is required";
    }
    else{
      console.log(formData.phoneNumber);
      if( !(formData.phoneNumber.match('[0-9]{10}')) ){
        //alert('Please provide valid phone number');
        errors.phoneNumber = "Please provide valid phone number";
      }else{
            //good to go
      }
    }
    if (!formData.eventDetails.trim()) {
      errors.eventDetails = "Event Details are required";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      setLoading(true); 
      var formDataNew = {
        first_name: formData.fullName,
        last_name: formData.fullName,
        email: formData.email,
        mobile: formData.phoneNumber,
        message: formData.eventDetails
      }
      var rawData = JSON.stringify(formDataNew);
      //setUserState(setId);
      UserDataService.contactFormSubmit(rawData)
        .then((response) => {
          setLoading(false);
          if (response.success) {
            //setCities(response.cities);
            setSubmisionMessage(response.message);
            setFormData({
              fullName: "",
              email: "",
              phoneNumber: "",
              eventDetails: ""
            });
          } else {
            let errors = {};
            if (response.message.first_name[0]) {
              errors.fullName = "Full Name is required";
            }
            if (response.message.email[0]) {
              errors.email = response.message.email[0];
            }
            if (response.message.mobile[0]) {
              errors.phoneNumber = response.message.mobile[0];
            }
            if (response.message.message) {
              errors.eventDetails = response.message.message;
            }
            setErrors(errors);
            return Object.keys(errors).length === 0;

          }
        })
        .catch((err) => {
          setLoading(false);
        });
      // Process your form submission here
      // console.log("Form data:", formData);
      // Example: You can make an API call or perform further actions
    }
  };
  return (
    <div>
      {seoMeta ? (
        <Meta
          title={seoMeta.title}
          description={seoMeta.description}
          keywords={seoMeta.keywords}
          site_name = {seoMeta.site_name}
          canonicalUrl = {seoMeta.canonicalUrl}
          url={seoMeta.url}
          image = {seoMeta.image}
        />
      ):null}
      <div className="navbarContainer-sticky Main-navbar-border">
        <MainNavbar isHomepage={false} />
      </div>
      <div>
        {/* <Contact_Form /> */}
        <div className="Contact_Form_Container">
          <div className="ContactUS_Section">
            <div className="ContactUS_Section_Heading">
              <h1>Contact Us</h1>
            </div>
            <div className="ContactUS_WhiteLinearGradint"></div>
          </div>
          <div className="contacForm_grid">
            <div className="FirstSection">
              <div className="FirstSectionSection1">
                <div className="heading_contact">
                  <h1>Customer Support:</h1>
                </div>
                <div className="textinfo_contact">
                <a href={`tel:+ 91 8291902662`}>
                  <p>
                    +91 8291902662 (7:00 AM - 9:00 PM) <br />{" "}
                    <a href="mailto:support@m2mferries.com">
                        support@m2mferries.com
                     </a>
                  </p>
                  </a>
                </div>
              </div>

              <div className="FirstSectionSection2">
                <div className="heading_contact">
                  {" "}
                  <h1>Business Enquiries:</h1>
                </div>
                <div className="textinfo_contact">
                    <a href="mailto:info@m2mferries.com">
                      <p>info@m2mferries.com</p>
                     </a>
                </div>
              </div>
              {/* <div className="FirstSectionSection3">
                <div className="heading_contact">
                  <h1>Head Office:</h1>
                </div>
                <div className="textinfo_contact">
                  <p>
                    Room 610, 6Th Floor, <br /> Vindhya Commercial Complex,{" "}
                    <br />
                    Sector 11, CBD Belapur, <br />
                    Navi Mumbai <br /> Mumbai <br /> 400614
                  </p>
                </div>
              </div> */}
            </div>

            <div className="SecondSection">
              <div className="headin2ndcontact">
                <h1>Submission Form</h1>
              </div>
              {/* <div className="SecondSection_content">
                <form action="">
                  <div className="form-group">
                    <label>Full Name</label>
                    <input placeholder="Enter Full Name" required />
                  </div>
                  <div className="form-group">
                    <label>Email Id</label>
                    <input placeholder="Enter Email Id" required />
                  </div>
                  <div className="form-group">
                    <label>Phone Number</label>
                    <input placeholder="Enter Phone Number" required />
                  </div>
                  <div id="textarea_input" className="form-group">
                      <label for="w3review">Event Details</label>
                      <textarea id="w3review" name="w3review" placeholder="Enter Event Details" rows="4" cols="50" required></textarea>
                  </div>


                  <button id="contactForm_input_button">Contact Us </button>
                </form>
              </div> */}

            <div className="SecondSection_content">

              {/* {submisionMessage ? (
              <div className="contact-us-sucess-message">{submisionMessage}</div>
              ):null} */}
              {submisionMessage ? (
                <div className="contact-us-sucess-message">{submisionMessage}</div>
              ) : (
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Full Name</label>
                  <input
                    type="text"
                    name="fullName"
                    placeholder="Enter Full Name"
                    value={formData.fullName}
                    onChange={handleInputChange}
                    className={errors.fullName ? "error" : ""}
                  />
                  {/* {errors.fullName && <p className="error-message">{errors.fullName}</p>} */}
                </div>
                <div className="form-group">
                  <label>Email Id</label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter Email Id"
                    value={formData.email}
                    onChange={handleInputChange}
                    className={errors.email ? "error" : ""}
                  />
                  {/* {errors.email && <p className="error-message">{errors.email}</p>} */}
                </div>
                <div className="form-group">
                  <label>Phone Number</label>
                  <input
                    type="tel"
                    name="phoneNumber"
                    placeholder="Enter Phone Number"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                    className={errors.phoneNumber ? "error" : ""}
                  />
                  {/* {errors.phoneNumber && <p className="error-message">{errors.phoneNumber}</p>} */}
                </div>
                <div id="textarea_input"  className="form-group">
                  <label>Details</label>
                  <textarea
                    name="eventDetails"
                    placeholder="Enter Details"
                    rows="4"
                    cols="50"
                    value={formData.eventDetails}
                    onChange={handleInputChange}
                    className={errors.eventDetails ? "error" : ""}
                  ></textarea>
                  {/* {errors.eventDetails && <p className="error-message">{errors.eventDetails}</p>} */}
                </div>
                <button type="submit" id="contactForm_input_button"  disabled={loading} >
                  {/* {loading ? "Submitting..." : "Contact Us"} */}
                  {loading ? <FaSpinner className="spinner" /> : "Contact Us"}
                  </button>
              </form>
            )}
            </div>
            </div>
          </div>
        </div>
         <div>
            <FotterAll />
          </div>
      </div>
    </div>
  );
};

export default ContactForm;
