import React, { useEffect, useState } from "react";
import "./BookingHistory.css";
import FotterAll from "../../Components/Footer/FotterAll";
import MainNavbar from "../../Components/Navbar/MainNavbar";
import TripDataService from "../../services/TripData";
import moment from "moment";
// import { useHistory } from 'react-router-dom';
import { Link, useNavigate } from "react-router-dom";


const BookingHistory = () => {
  const history = useNavigate();

  // sort functlity button
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Show Only");
  const [userBookings, setUserBookings] = useState([]);
  const [userAllBookings, setUserAllBookings] = useState([]);

  // For selct Option
  const options = ["Show All", "Confirmed", "Completed", "Canceled", "Pending"];

  const [filter, setFilter] = useState("Show Only");

  const toggleOptions = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setFilter(option);
    setIsOpen(false);
    filterRows(option);
  };

  const filterRows = (rowStatus) => {
    const today = moment();
    var filterdBookings = "";
    if(rowStatus == "Confirmed"){
      filterdBookings = userAllBookings.filter(d => moment(d.sailing_date, "DD MMM, YYYY HH:mm").isSameOrAfter(today, 'day') && (d.status === "Booked"));
      
    }
    else if(rowStatus == "Completed"){
      filterdBookings = userAllBookings.filter(d => moment(d.sailing_date, "DD MMM, YYYY HH:mm").isBefore(today, 'day') && (d.status === "Booked" || d.status === 'Pending'));
     
    }
    else if(rowStatus == "Canceled")
    {
      filterdBookings = userAllBookings.filter(booking => booking.status === 'Cancelled');
    }
    else if(rowStatus == "Pending")
    {
      filterdBookings = userAllBookings.filter(d => moment(d.sailing_date, "DD MMM, YYYY HH:mm").isSameOrAfter(today, 'day') && (d.status === "Pending"));
    }
    else {
      filterdBookings = userAllBookings;
    }
    
    setUserBookings(filterdBookings);
    
    
    /* const uppercaseRowStatus = rowStatus.toUpperCase();
    const uppercaseFilter = filter.toUpperCase();

    if (uppercaseFilter === "SHOW ONLY") {
      return true;
    }
    return uppercaseRowStatus.includes(uppercaseFilter); */
  };

  // The UserData Not Avilable To Redirect To  Login Page


  const getDate = (year, isFirst) => {
    return moment(new Date(year, isFirst ? 0 : 11, isFirst ? 1 : 31)).format('MM/DD/YYYY');
  };
   // AutoMatic Scrolling Top
 useEffect(() => {
  const UserData1 = JSON.parse(localStorage.getItem("userData"));
    if(!UserData1){
      history('/login')
    }
    
  // Scroll to the top when the component mounts
  var year = new Date().getFullYear();
  var currYear = new Date().getFullYear();
  var end_date = getDate(year,false)
  if(currYear == year){
    end_date = moment(new Date()).format('MM/DD/YYYY');
  }
  
  var rawData = JSON.stringify({
    customer_phone: UserData1.mobile,
    //customer_phone: '9167243409',
    booking_start_date: getDate(year,true),
    booking_end_date: end_date,
      //end_date = moment(new Date()).format('MM/DD/YYYY');
  });

  TripDataService.fetchCustomerBooking(rawData)
        .then((response) => {
            setUserBookings(response.booking);
            setUserAllBookings(response.booking);
            //setPageContent(response.data[1].description);
        })
  }, []);
 
useEffect(() => {
  // Scroll to the top when the component mounts
  window.scrollTo(0, 0);
}, []);


  // full ticket showud be view
  const [formSectionVisible, setFormSectionVisible] = useState(true);
  const [newSectionVisible, setNewSectionVisible] = useState(false);
  const [bookingDetails, setBookingDetails] = useState("");
  const [selectedBookingDetails, setSelectedBookingDetails] = useState("");

  const handleSubmitToSections = (booking) => {
    if(booking.status !== "Cancelled"){
      setSelectedBookingDetails(booking);
      setFormSectionVisible(false);
      setNewSectionVisible(true);
      
      var rawData = JSON.stringify({
        order_id: booking.booking_id,
          //end_date = moment(new Date()).format('MM/DD/YYYY');
      });
    
      TripDataService.fetchCustomerBookingDetails(rawData)
      .then((response) => {
          setBookingDetails(response);
          //setUserBookings(response.booking);
          //setPageContent(response.data[1].description);
      })
    }
    
    
  };

  const handleSubmitReverse = () => {
    setFormSectionVisible(true);
    setNewSectionVisible(false);
  };
  //console.log(bookingDetails.guest_detail)
  //const count = bookingDetails.guest_detail.filter(item => item.name.includes("infant")).length;

  const countInfantNames = (guestArray, keyword, type) => {
    let count = 0;
    if(type === "Passenger"){
      count = guestArray.filter(item => item.name.includes(keyword)).length;
    }
    if(type === "Supplement"){
      var supplementArray = guestArray.filter(item => item.supplement_name.includes(keyword));
      if(supplementArray.length > 0){
        count = supplementArray[0].quantity;
      }
    }
    return count;
  };

  // Function to get the passenger display text
const getPassengerDisplayText = (guestArray) => {
  const totalPassengers = guestArray.length;
  if (totalPassengers > 1) {
    return `${guestArray[0].name} +${totalPassengers - 1}`;
  } else if (totalPassengers === 1) {
    return guestArray[0].name;
  } else {
    return "";
  }
};

  
  if(bookingDetails){
    //countInfantNames(bookingDetails.return_supplement_details, 'Bicycle', "Supplement");
    //const count = bookingDetails.guest_detail.filter(item => item.name.includes("infant")).length;
    //const infantCount = countInfantNames(bookingDetails.guest_detail);
    //console.log("Number of objects with 'infant' in name:", infantCount);
  }  
  //console.log(count);
  return (
    <>
      <div className="navbarContainer-sticky Main-navbar-border">
        <MainNavbar isHomepage={false} />
      </div>
      <div>
        {formSectionVisible && (
          <div className="TicketPAges_Content">
            <div className="TicketPage_BookHIstoryHEading">
              <h1>Booking History</h1>
              <div className="TicketPage_select">
                <div className={`TicketPage_select_header ${isOpen ? "open" : ""}`} >
                  <div className="TicketPage_select_button" onClick={toggleOptions}>
                    <p>{selectedOption}</p>
                    <span className={`TicketPage_select_button_Unicode ${   isOpen ? "rotate" : ""}`}>&#62;</span>
                  </div>
                </div>
                <div className={`TicketPage_options ${isOpen ? "open" : ""}`}>
                  {options.map((option, index) => (
                    <div className="TicketPage_option" key={index} onClick={() => handleOptionClick(option)}>
                      {option}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* Listing Desktop Veiw */}
            
              {/* {userBookings.sort((a, b) => (b.booking_id - a.booking_id)).map((booking, index) => ( */}
              {Array.isArray(userBookings) && userBookings.length > 0 ? (
                  userBookings
                  .sort((a, b) => b.booking_id - a.booking_id)
                  .map((booking, index) => (
                      <div key={`keyIDs${index}`}>
                        <div className="DesktopView_TicketPAge" key={`deskls${index}`} id={`deskls${index}`}>
                          <div className="TicketPage_booking_lines" onClick={() => handleSubmitToSections(booking)}>
                            <div className="TicketPage_booking_line_name">
                              <h1>{booking.ferry_name}</h1>

                              <p>{/* {booking.booking_type} | {" "} */}
                              
                              {booking.sailing_way == "onward"?(
                                " Single Journey | "
                              ):(
                                " Round Trip | "
                              )}


                                <span className="TicketPage_Status" style={{ 
                                        color: booking.status === 'Booked' ?  moment(booking.sailing_date, 'DD MMM, YYYY HH:mm').isAfter(moment(), 'day') || moment(booking.sailing_date, 'DD MMM, YYYY HH:mm').isSame(moment(), 'day') ? '#03AE00' : '#767C8D' : booking.status === 'Cancelled' ? '#FFC229' : 'inherit' 
                                        }}>
                                        {booking.status === 'Booked' ? 
                                            moment(booking.sailing_date, 'DD MMM, YYYY HH:mm').isAfter(moment(), 'day') || moment(booking.sailing_date, 'DD MMM, YYYY HH:mm').isSame(moment(), 'day') ? 'CONFIRMED' :
                                            'COMPLETED' :
                                        booking.status === 'Cancelled' ? 'CANCELLED' :
                                        booking.status}
                                </span>
                              </p>

                            </div>

                            <div className="TicketPage_booking_line_Info">
                              <div className="line_Info_BookingID">
                                <h1>Booking ID</h1>
                                <p>{booking.booking_id}</p>
                              </div>
                              <div className="line_Info_DepartureDate">
                                <h1>Departure Date</h1>
                                <p>{moment(booking.sailing_date, 'DD MMM, YYYY HH:mm').format("DD MMM, YYYY")}</p>
                              </div>
                              <div className="line_Info_ReturnDate">
                                <h1>Return Date</h1>
                                {(booking.booking_type === "MUM-MUM" || booking.booking_type === "MWA-MWA")?(
                                  <p>{moment(booking.return_date, 'DD MMM, YYYY HH:mm').format("DD MMM, YYYY")}</p>
                                ):<p>N/A</p>}
                              </div>
                              
                              <div className="line_Info_Price">
                                <h1>Amount</h1>
                                <p>₹ {booking.booking_value.replace("Rs.", "")}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Mobile View */}
                        <div className="MobileView_TicketPage" key={`mobls${index}`}>
                          <div className="TicketPageForLine_mobile" onClick={() => handleSubmitToSections(booking)}>
                            <div className="TicketPageForLine_mobile_DivOne">
                              <div className="Ticket_Mobile_DivOne_SectionOne">
                                <h1>{booking.ferry_name}</h1>
                                <p>{booking.sailing_way == "onward"?(
                                " Single Journey "
                              ):(
                                " Round Trip "
                              )}</p>
                              </div>
                              <div className="Ticket_Mobile_DivOne_SectionTwo">
                                <p>Amount</p>
                                <h2>₹ {booking.booking_value.replace("Rs.", "")}</h2>
                              </div>
                            </div>
                            <div className="TicketPageForLine_mobile_DivTwo">
                              <div className="Ticket_Mobile_SectionTwo">
                                <h1>Departure</h1>
                                <p>{moment(booking.sailing_date, 'DD MMM, YYYY HH:mm').format("DD MMM, YYYY")}</p>
                              </div>
                              <div className="Ticket_Mobile_SectionTwo">
                                {(booking.booking_type === "MUM-MUM" || booking.booking_type === "MWA-MWA")?(
                                  <>
                                  <h1>Return</h1>
                                  <p>{moment(booking.return_date, 'DD MMM, YYYY HH:mm').format("DD MMM, YYYY")}</p>
                                  </>
                                  
                                ):<p>&nbsp;</p>}
                              </div>
                            </div>{" "}
                            <div className="TicketPageForLine_mobile_DivThree">
                              <div className="Ticket_Mobile_SectionThree">
                                <h1>Booking ID</h1>
                                <h2>{booking.booking_id}</h2>
                              </div>
                              <div className="Ticket_Mobile_SectionThree">
                                <p className="TicketPage_Status" style={{ color: booking.status === 'Booked' ?  moment(booking.sailing_date, 'DD MMM, YYYY HH:mm').isAfter(moment(), 'day') || moment(booking.sailing_date, 'DD MMM, YYYY HH:mm').isSame(moment(), 'day') ? '#03AE00' : '#767C8D' : booking.status === 'Cancelled' ? '#FFC229' : 'inherit' }}>
                                          {booking.status === 'Booked' ? moment(booking.sailing_date, 'DD MMM, YYYY HH:mm').isAfter(moment(), 'day') || moment(booking.sailing_date, 'DD MMM, YYYY HH:mm').isSame(moment(), 'day') ? 'CONFIRMED' :'COMPLETED' :booking.status === 'Cancelled' ? 'CANCELLED' :booking.status}
                                </p>
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    ))
              ) : (
                <div className="sort-no-booking-message">
                  <h1>There are no bookings</h1>
                  <div className="sort-no-booking-message-btn">
                    <Link to={"/"}>
                       <button className="nav_resume_btn_fullTicket">Book Now</button>
                    </Link>
                  </div>
                </div> 
              )}
              {/* ))} */}


           </div>
        )}

        {/* Mobile View */}

        {newSectionVisible && (
          <>
            <div className="FulllTicket_DesktopView">
              <div className="TicketPAges_Content">
                <div className="TicketPAges_Content">
                  <div className="FullTicket_Heading">
                    <h1  onClick={handleSubmitReverse}>Booking History</h1>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12.705" height="21.229" viewBox="0 0 12.705 21.229">
                      <path id="Alt_Arrow_Down" data-name="Alt Arrow Down" d="M21.988,9l-8.494,9.084L5,9" transform="translate(-6.88 24.109) rotate(-90)" fill="none" stroke="#767c8d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                    </svg>
                   <h3>Booking Details</h3>
                 </div>

                  <div className="FullTicket_Content">
                  {bookingDetails? (
                      <>
                      <div className="FullTicket_Content_full">
                        <div className="FullTicket_Content_info">
                          <div className="FullTicket_Content_info_div1">
                            <h1>{selectedBookingDetails.ferry_name}</h1>
                            <p >
                              Booking {"  "}
                              <span className="Tic{ketPage_Status" style={{ color: selectedBookingDetails.status === 'Booked' ?  moment(selectedBookingDetails.sailing_date, 'DD MMM, YYYY HH:mm').isAfter(moment(), 'day') || moment(selectedBookingDetails.sailing_date, 'DD MMM, YYYY HH:mm').isSame(moment(), 'day') ? '#03AE00' : '#767C8D' : selectedBookingDetails.status === 'Cancelled' ? '#FFC229' : 'inherit' }}>
                                  {selectedBookingDetails.status === 'Booked' ? moment(selectedBookingDetails.sailing_date, 'DD MMM, YYYY HH:mm').isAfter(moment(), 'day') || moment(selectedBookingDetails.sailing_date, 'DD MMM, YYYY HH:mm').isSame(moment(), 'day') ? 'CONFIRMED' :'COMPLETED' :selectedBookingDetails.status === 'Cancelled' ? 'CANCELLED' :selectedBookingDetails.status}
                              </span>
                            </p>
                                  
                          </div>
                          <div className="FullTicket_Content_info_div2">
                            <div className="info_div2_Book_Id">
                              <h5>Booking ID</h5>
                              <p>{bookingDetails.booking_id}</p>
                            </div>

                            <div className="info_div2_TripAndPrice">
                              <div>
                                <h5>Trip</h5>
                                <p>{bookingDetails.sailing_way === "return" ? "Round Trip": "Single Journey"}</p>
                              </div>
                              
                              <div>
                                <h5>Route</h5>
                                {selectedBookingDetails.booking_type === "MUM-MUM" ?(
                                  <p>MUM-MWA-MUM</p>
                                ):(
                                  <>
                                  {selectedBookingDetails.booking_type === "MWA-MWA" ?(
                                    <p>MWA-MUM-MWA</p>
                                  ):<p>{selectedBookingDetails.booking_type}</p>}
                                  </>
                                )}
                              </div>

                              <div>
                                <h5>Total Price</h5>
                                <p>₹ {selectedBookingDetails.booking_value.replace("Rs.", "")}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="FullTicket_Content_Qrcode">
                          <img src={bookingDetails.qr_code} alt="Ticket_QrCode" />
                        </div>
                      </div>

                      {/*Departure 1st line tabloe */}
                      <div className="FullTicket_Content_Departure">
                        <div className="FullTicket_Content_Departure_heading">
                            <h1>Departure: {bookingDetails.onward_journey.origin} To {bookingDetails.onward_journey.destination }</h1>
                        </div>
                        <table className="FullTicket_Content_Table">
                          <tr>
                            <th>No. of Stops</th>
                            <th>Date of Departure</th>
                            <th>Departure</th>
                            <th>Arrival</th>
                            <th>Seating Categories</th>
                            <th>Passenger</th>
                            <th>Vehicle</th>
                          </tr>
                          <tr>
                            <td>Non-Stop</td>
                            <td>{moment(bookingDetails.onward_journey.sailing_start_date).format("DD MMMM, YYYY")}</td>
                            <td>{moment(bookingDetails.onward_journey.sailing_start_date).format("hh:mm A")}</td>
                            <td>{moment(bookingDetails.onward_journey.sailing_end_date).format("hh:mm A")}</td>
                            <td>{bookingDetails.guest_detail[0].onward_Detail.room_category_name}</td>
                            <td>
                              {/* <p>Passenger : {bookingDetails.guest_detail[0].name} </p> */}
                              <p>Passenger: {getPassengerDisplayText(bookingDetails.guest_detail)}</p>
                              <p>Infant {countInfantNames(bookingDetails.guest_detail, 'infant', "Passenger")}</p>
                            </td>
                            <td>
                                {bookingDetails.onward_supplement_details ? (
                                  <>
                                    {bookingDetails.onward_supplement_details.map((supplement, index) => (
                                      <div key={`desk${index}`}>
                                        {(supplement.supplement_id !== 605606 && supplement.supplement_id !== 605604 && supplement.supplement_id !== 605605) ? (
                                            <p>Car {(index + 1)}: {supplement.supplement_name} </p>
                                        ) : (supplement.supplement_id === 605606)? ( // Motorcycle
                                            <p>Two Wheeler: {supplement.quantity} </p>
                                        ): (supplement.supplement_id === 605605)? ( // Bicycle
                                          <p>Bicycle: {supplement.quantity} </p>
                                        ):(supplement.supplement_id === 605604)? ( //Pet
                                            <p>Pet: {supplement.quantity} </p>
                                        ):null}
                                      </div>
                                    ))}
                                  </>
                                ) : null}
                            </td>
                          </tr>
                        </table>
                      </div>
                      
                      {/*Return 1st line */}
                      {bookingDetails.sailing_way === "return" ? (
                        <>
                          <div className="FullTicket_Content_Departure">
                            <div className="FullTicket_Content_Departure_heading">
                              <h1>Return: {bookingDetails.return_journey.origin} To {bookingDetails.return_journey.destination }</h1>
                            </div>
                            <table className="FullTicket_Content_Table">
                              <tr>
                                <th>No. of Stops</th>
                                <th>Date of Departure</th>
                                <th>Departure</th>
                                <th>Arrival</th>
                                <th>Seating Categories</th>
                                <th>Passenger</th>
                                <th>Vehicle</th>
                              </tr>
                              <tr>
                                <td>Non-Stop</td>
                                <td>{moment(bookingDetails.return_journey.sailing_start_date).format("DD MMMM, YYYY")}</td>
                                <td>{moment(bookingDetails.return_journey.sailing_start_date).format("hh:mm A")}</td>
                                <td>{moment(bookingDetails.return_journey.sailing_end_date).format("hh:mm A")}</td>
                                <td>{bookingDetails.guest_detail[0].return_Detail.room_category_name}</td>
                                <td>
                                  {/* <p>Passenger : {bookingDetails.guest_detail[0].name} </p> */}
                                  <p>Passenger: {getPassengerDisplayText(bookingDetails.guest_detail)}</p>
                                  <p>Infant {countInfantNames(bookingDetails.guest_detail, 'infant', "Passenger")}</p>
                                </td>
                                <td>
                                  {bookingDetails.return_supplement_details ? (
                                    <>
                                      {bookingDetails.return_supplement_details.map((supplement, index) => (
                                        <div key={`deskret${index}`}>
                                          {(supplement.supplement_id !== 605606 && supplement.supplement_id !== 605604 && supplement.supplement_id !== 605605) ? (
                                              <p>Car {(index + 1)}: {supplement.supplement_name} </p>
                                          ) : (supplement.supplement_id === 605606)? ( // Motorcycle
                                              <p>Two Wheeler: {supplement.quantity} </p>
                                          ): (supplement.supplement_id === 605605)? ( // Bicycle
                                            <p>Bicycle: {supplement.quantity} </p>
                                          ):(supplement.supplement_id === 605604)? ( //Pet
                                              <p>Pet: {supplement.quantity} </p>
                                          ):null}
                                        </div>
                                      ))}
                                    </>
                                  ) : null}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </>
                        
                      ):null}

                      </>
                    ):null}

                    {/* Button content */}
                    <div className="FullTicketButton">
                      <div><button className="nav_resume_btn_fullTicket">View on app</button></div>
                      <div><button className="nav_resume_btn_fullTicket">Download ticket</button></div>
                    </div>
                    <div className="Horizontal_lineFullTicket"></div>
                    <div className="GetE_Ticket">
                        <p>Get the E-ticket on your email address</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="FullTicket_MobileView">
              <div className="FullTicket_Heading">
                    <h1  onClick={handleSubmitReverse}>Booking History</h1>
                    <svg className="SvgForTickirt" xmlns="http://www.w3.org/2000/svg" width="12.705" height="21.229" viewBox="0 0 12.705 21.229">
                      <path id="Alt_Arrow_Down" data-name="Alt Arrow Down" d="M21.988,9l-8.494,9.084L5,9" transform="translate(-6.88 24.109) rotate(-90)" fill="none" stroke="#767c8d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                    </svg>
                   <h3>Booking Details</h3>
                 </div>

             {bookingDetails? (
                <div className="FullTicket_Content_Mobile">
                  <div className="FullTicket_Content_Mobile_Div1">
                    <div className="Content_Mobile_Div1_SectionOne">
                      <h1>Vessel Name</h1>
                      <p>{selectedBookingDetails.ferry_name}</p>
                    </div>
                    <div className="Content_Mobile_Div1_SectionTwo">
                      <h1>Amount</h1>
                      <p>₹ {selectedBookingDetails.booking_value.replace("Rs.", "")}</p>
                    </div>
                    
                  </div>
                  <div className="FullTicket_Content_Mobile_Div1">
                    <div className="Content_Mobile_Div1_SectionOne">
                      <h1>Trip</h1>
                      <p>{bookingDetails.sailing_way === "return" ? "Round Trip": "Single Journey"}</p>
                    </div>
                    <div className="Content_Mobile_Div1_SectionTwo">
                      <h1>Route</h1>
                      {selectedBookingDetails.booking_type === "MUM-MUM" ?(
                        <p>MUM-MWA-MUM</p>
                      ):(
                        <>
                        {selectedBookingDetails.booking_type === "MWA-MWA" ?(
                          <p>MWA-MUM-MWA</p>
                        ):<p>{selectedBookingDetails.booking_type}</p>}
                        </>
                      )}

                    </div>
                  </div>
                  <div className="FullTicket_Content_Mobile_Div2">
                    <div className="Content_Mobile_Div2_SectionOne">
                      <h1>Booking ID</h1>
                      <p>{bookingDetails.booking_id}</p>
                    </div>
                    <div className="Content_Mobile_Div2_SectionOne">
                      <h5>Booking {selectedBookingDetails.status}</h5>
                    </div>
                  </div>
                  <div className="FullTicket_Content_Mobile_Div4_Qr">
                    <img src={bookingDetails.qr_code} alt="Ticket_QrCode"/>
                  </div>
                  {/* Departure */}
                  <div className="FullTicket_Page_Line_Mobile">
                    <div className="FullTicket_Page_Line_Mobile_heading">
                      <h1>Departure: {bookingDetails.onward_journey.origin} To {bookingDetails.onward_journey.destination }</h1>
                    </div>
                    <div className="FullTicket_Page_Line_Mobile_Comman">
                      <div className="Mobile_Comman_SectionOne">
                        <h1>No. of Stops</h1>
                        <p>Non-Stop</p>
                      </div>
                      <div className="Mobile_Comman_SectionTwo">
                        <h1>Date of Departure</h1>
                        <p>{moment(bookingDetails.onward_journey.sailing_start_date).format("DD MMMM, YYYY")}</p>
                      </div>
                    </div>
                    <div className="FullTicket_Page_Line_Mobile_Comman">
                      <div className="Mobile_Comman_SectionOne">
                        <h1>Departure</h1>
                        <p>{moment(bookingDetails.onward_journey.sailing_start_date).format("hh:mm A")}</p>
                      </div>
                      <div className="Mobile_Comman_SectionTwo">
                        <h1>Arrival</h1>
                        <p>{moment(bookingDetails.onward_journey.sailing_end_date).format("hh:mm A")}</p>
                      </div>
                    </div>

                    <div className="FullTicket_Page_Line_Mobile_Comman">
                      <div className="Mobile_Comman_SectionOne">
                        <h1>Seating Categories</h1>
                        <p>{bookingDetails.guest_detail[0].onward_Detail.room_category_name}</p>
                      </div>
                    </div>

                    <div className="FullTicket_Page_Line_Mobile_Comman">
                      <div className="Mobile_Comman_SectionOne">
                        <h1>Passanger</h1>
                        <p>Passenger : {bookingDetails.guest_detail[0].name} </p>
                        <p>Infant {countInfantNames(bookingDetails.guest_detail, 'infant', "Passenger")}</p>
                      </div>
                    </div>

                    <div className="FullTicket_Page_Line_Mobile_Comman">
                      <div className="Mobile_Comman_SectionOne">
                        <h1>Vehicle</h1>
                        {bookingDetails.onward_supplement_details ? (
                          <>
                            {bookingDetails.onward_supplement_details.map((supplement, index) => (
                              <div key={`mobile${index}`}>
                                {(supplement.supplement_id !== 605606 && supplement.supplement_id !== 605604 && supplement.supplement_id !== 605605) ? (
                                    <p>Car {(index + 1)}: {supplement.supplement_name} </p>
                                ) : (supplement.supplement_id === 605606)? ( // Motorcycle
                                    <p>Two Wheeler: {supplement.quantity} </p>
                                ): (supplement.supplement_id === 605605)? ( // Bicycle
                                  <p>Bicycle: {supplement.quantity} </p>
                                ):(supplement.supplement_id === 605604)? ( //Pet
                                    <p>Pet: {supplement.quantity} </p>
                                ):null}
                              </div>
                            ))}
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {/* REturn */}
                  {bookingDetails.sailing_way === "return" ? (
                      <div className="FullTicket_Page_Line_Mobile">
                        <div className="FullTicket_Page_Line_Mobile_heading">
                          <h1>Return: {bookingDetails.return_journey.origin} To {bookingDetails.return_journey.destination }</h1>
                        </div>
                        <div className="FullTicket_Page_Line_Mobile_Comman">
                          <div className="Mobile_Comman_SectionOne">
                            <h1>No. of Stops</h1>
                            <p>Non-Stop</p>
                          </div>
                          <div className="Mobile_Comman_SectionTwo">
                            <h1>Date Of Departure</h1>
                            <p>{moment(bookingDetails.return_journey.sailing_start_date).format("DD MMMM, YYYY")}</p>
                          </div>
                        </div>
                        <div className="FullTicket_Page_Line_Mobile_Comman">
                          <div className="Mobile_Comman_SectionOne">
                            <h1>Departure</h1>
                            <p>{moment(bookingDetails.return_journey.sailing_start_date).format("hh:mm A")}</p>
                          </div>
                          <div className="Mobile_Comman_SectionTwo">
                            <h1>Arrival</h1>
                            <p>{moment(bookingDetails.return_journey.sailing_end_date).format("hh:mm A")}</p>
                          </div>
                        </div>

                        <div className="FullTicket_Page_Line_Mobile_Comman">
                          <div className="Mobile_Comman_SectionOne">
                            <h1>Seating Categories</h1>
                            <p>{bookingDetails.guest_detail[0].onward_Detail.room_category_name}</p>
                          </div>
                        </div>

                        <div className="FullTicket_Page_Line_Mobile_Comman">
                          <div className="Mobile_Comman_SectionOne">
                            <h1>Passenger</h1>
                              <p>Passenger : {bookingDetails.guest_detail[0].name} </p>
                              <p>Infant {countInfantNames(bookingDetails.guest_detail, 'infant', "Passenger")}</p>
                          </div>
                        </div>

                        <div className="FullTicket_Page_Line_Mobile_Comman">
                          <div className="Mobile_Comman_SectionOne">
                            <h1>Vehicle</h1>
                            {/* <p>Car 1: Hyundai I20 </p>
                            <p>Two Wheeler 1 </p> */}
                            {bookingDetails.return_supplement_details ? (
                              <>
                                {bookingDetails.return_supplement_details.map((supplement, index) => (
                                  <div key={`mobileret${index}`}>
                                    {(supplement.supplement_id !== 605606 && supplement.supplement_id !== 605604 && supplement.supplement_id !== 605605) ? (
                                        <p>Car {(index + 1)}: {supplement.supplement_name} </p>
                                    ) : (supplement.supplement_id === 605606)? ( // Motorcycle
                                        <p>Two Wheeler: {supplement.quantity} </p>
                                    ): (supplement.supplement_id === 605605)? ( // Bicycle
                                      <p>Bicycle: {supplement.quantity} </p>
                                    ):(supplement.supplement_id === 605604)? ( //Pet
                                        <p>Pet: {supplement.quantity} </p>
                                    ):null}
                                  </div>
                                ))}
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                  ):null}

                  {/* button css start */}
                  <div className="FullTicketButton">
                    <div className="FullTicketButton_div1">
                      <button>View on app</button>
                      <button>Download ticket</button>
                    </div>
                    <div className="ButtonTicketFull_Horizontal_Line"></div>
                    <div className="FullTicketButton_div2">
                      <p>Get the E-ticket on your email address</p>
                    </div>
                  </div>
                </div>
              ):null}

            </div>
          </>
        )}
      </div>

      <div className="TicketPAges_Footer">
        <FotterAll />
      </div>
    </>
  );
};

export default BookingHistory;
