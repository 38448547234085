/* var myHeaders = new Headers();
myHeaders.append("Authorization", "Basic YmFoYW1hc3BhcmFkaXNlcmV0YWlsOjFKUkw1SDNXSlhPRE1U");
myHeaders.append("Content-Type", "application/json");

var captureUrl = 'https://m2m.kapturecrm.com/';

export const fetchTrips = async (rawData) => {
    try {
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: rawData,
            redirect: 'follow'
        };
      const response = await fetch(`${captureUrl}cruise-sailing-pricing-details`, requestOptions);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    } catch (error) {
      throw new Error('Failed to fetch data');
    }
}; */

const ENV_MODE = process.env.REACT_APP_ENV;

var myHeaders = new Headers();
myHeaders.append(
  "Authorization",
  "Basic "+process.env.REACT_APP_CAPTURE_TOCKEN
);
myHeaders.append("Content-Type", "application/json");

//var M2MApiUrl = "https://appapi.m2mferries.com/";
//var M2MApiUrl = "https://m2mapi.mayradigital.com/";

var captureUrl = process.env.REACT_APP_VAR_CAPTURE_URL;
var M2MApiUrl = process.env.REACT_APP_VAR_M2MAPI_URL;
class TripDataService {

  fetchTrips = async (rawData) => {
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: rawData,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${captureUrl}cruise-sailing-pricing-details`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
        // throw setIsBrowserAlerts("Network response was not ok");
      }
      return response.json();
    } catch (error) {
      throw new Error("Failed to fetch data");
    }
  };
  
  fetchCruiseCategoryAvailability = async (rawData) => {
    //var rawData = {sailing_date: "01/27/2024", sailing_time: "07:00:00", sailing_type: "custom", cruise_id: 54}
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: rawData,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${captureUrl}get-cruise-category-availability`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    } catch (error) {
      throw new Error("Failed to fetch data");
    }
  };

  fetchCruiseRoomAvailability = async (rawData) => {
    //var rawData = {sailing_date: "01/27/2024", sailing_time: "07:00:00", sailing_type: "custom", cruise_id: 54}
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: rawData,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${captureUrl}search-cruise-room-availability-block`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    } catch (error) {
      throw new Error("Failed to fetch data");
    }
  };

  
  fetchCruiseBookingGuestPreview = async (rawData) => {
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: rawData,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${captureUrl}get-cruise-booking-guest-booking-preview`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    } catch (error) {
      throw new Error("Failed to fetch data");
    }
  };


  fetchCruiseBookingConfirm = async (rawData) => {
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: rawData,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${captureUrl}confirm-cruise-booking-guest-booking`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    } catch (error) {
      throw new Error("Failed to fetch data");
    }
  };
  
  fetchCruiseCustomBookingPreview = async (rawData) => {
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: rawData,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${captureUrl}cruise-custom-booking-priview`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    } catch (error) {
      throw new Error("Failed to fetch data");
    }
  };
  
  fetchCruiseCustomBookingConfirm = async (rawData) => {
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: rawData,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${captureUrl}cruise-custom-manual-booking-confirmation`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    } catch (error) {
      throw new Error("Failed to fetch data");
    }
  };


  /* fetchCruiseCategoryAvailability = async (rawData) => {
    //var rawData = {sailing_date: "01/27/2024", sailing_time: "07:00:00", sailing_type: "custom", cruise_id: 54}
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: rawData,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${captureUrl}get-cruise-category-availability`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    } catch (error) {
      throw new Error("Failed to fetch data");
    }
  }; */

  fetchVehicles = async (rawData) => {
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: rawData,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${captureUrl}get-cruise-supplement-list-for-sailing`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    } catch (error) {
      throw new Error("Failed to fetch data");
    }
  };

  fetchTripsInfo = async (data) => {
    try {
      const response = await fetch(`${M2MApiUrl}api/trip-data`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    } catch (error) {
      throw new Error("Failed to post data");
    }
  };

  fetchMultipleTripVehicles = async (rawData) => {
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: rawData,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${captureUrl}get-cruise-supplement-list-for-multiple-sailing`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    } catch (error) {
      throw new Error("Failed to fetch data");
    }
  };

  fetchCustomerBooking = async (rawData) => {
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: rawData,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${captureUrl}all-customer-booking`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    } catch (error) {
      throw new Error("Failed to fetch data");
    }
  };
  
  fetchCustomerBookingDetails = async (rawData) => {
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: rawData,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${captureUrl}get-ferry-booking-check-in-details`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    } catch (error) {
      throw new Error("Failed to fetch data");
    }
  };
}

export default new TripDataService();
