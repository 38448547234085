import React, { useEffect, useState } from "react";

import HomePageCompOne from "../../Components/HomePageComp/HomePageCompOne";
import NewWidget from "../../Components/HomePageComp/NewWidget";
import Our_Partners from "../../Components/HomePageComp/Our_Partners";
// import Explore_Mandwa from "../../Components/HomePageComp/Explore_Mandwa";
import Testimonials_com from "../../Components/HomePageComp/Testimonials_com";
import FotterAll from "../../Components/Footer/FotterAll";
import MainNavbar from "../../Components/Navbar/MainNavbar";
import BookOnGo from "../../Components/HomePageComp/BookOnGo";
import Experience_Ferries from "../../Components/HomePageComp/Experience_Ferries";
import Traveling_To_Alibaug from "../../Components/HomePageComp/Traveling_To_Alibaug";
import Meta from '../../Components/Meta';
import StaticDataService from "../../services/staticData";

const HomePage = () => {
  
  const [seoMeta, setSeoMeta] = useState(null);
  const [mainBanners, setMainBanners] = useState(null);
  const [mainBannersMobile, setMainBannersMobile] = useState(null);
  const [isScrolledOnHomepage, setIsScrolledOnHomepage] = useState(false);
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  // console.log("API URL:", process.env.REACT_APP_API_URL);
  // console.log("Environment:", process.env.REACT_APP_ENV);
    
  useEffect(() => {
    
    /* StaticDataService.HomeMainBanners()
      .then((response) => {
        if (response.status !== "error") {
          setMainBanners(response.webmainbanner);
          setMainBannersMobile(response.webmainbannermobile);
        } else {
          /* setError(response.message); *
        }
      })
      .catch((err) => {
        //setError(err.message);
        //setLoading(false);
      }); */
    
    
      StaticDataService.SeoMetaServices('Homepage')
      .then((response) => {
        if (response.status !== "error") {
          
          setSeoMeta(response.seoMeta[0]);
         
        } else {
          /* setError(response.message); */
        }
      })
      .catch((err) => {
        //setError(err.message);
        //setLoading(false);
      });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolledOnHomepage(window.scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      {seoMeta ? (
      <Meta 
        title={seoMeta.title}
        description={seoMeta.description}
        keywords={seoMeta.keywords}
        site_name = {seoMeta.site_name}
        canonicalUrl = {seoMeta.canonicalUrl}
        url={seoMeta.url}
        image = {seoMeta.image}
      />
      ):null}
      <MainNavbar isHomepage={true} isScrolled={isScrolledOnHomepage} />
      <HomePageCompOne />
      <NewWidget />
      <Experience_Ferries />
      <Traveling_To_Alibaug />
      <BookOnGo/> 
      <Our_Partners showParentHeading={true} />
      <Testimonials_com />
      {/* <Explore_Mandwa showImage={true} slidesToShow={3} /> */}
      <FotterAll />
    </div>
  );
};

export default HomePage;
