import React, { useRef, useState } from "react";
import "./Parents_thank.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Parents_thank = () => {
  const slides_Parent = [
    {
      id: 1,
      imageSrc: "/assets/img/Partners/partnerSlider_1.png",
      heading: "Enjoy The Whole World And Enjoy Its Beauty",
      text: "Thinking of taking a break from busy Life? Planning to go out of the state with your loved ones to spend some quality time?",
      buttonText: "Know More",
    },
    {
      id: 2,
      imageSrc: "/assets/img/Partners/mask_group.png",
      heading: "Enjoy The Whole World And Enjoy Its Beauty",
      text: "Thinking of taking a break from busy Life? Planning to go out of the state with your loved ones to spend some quality time?",
      buttonText: "Know More",
    },
    {
      id: 3,
      imageSrc: "/assets/img/Partners/partnerSlider_1.png",
      heading: "Enjoy The Whole World And Enjoy Its Beauty",
      text: "Thinking of taking a break from busy Life? Planning to go out of the state with your loved ones to spend some quality time?",
      buttonText: "Know More",
    },
  ];

  const [currentPage_Parent, setCurrentPage_Parent] = useState(1);
  const sliderRef_Parent = useRef(null);

  const settings_Parent = {
    dots: false,
    arrow: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const goToNextPage_Parent = () => {
    if (currentPage_Parent < slides_Parent.length) {
      setCurrentPage_Parent(currentPage_Parent + 1);
      sliderRef_Parent.current.slickNext();
    }
  };

  const goToPrevPage_Parent = () => {
    if (currentPage_Parent > 1) {
      setCurrentPage_Parent(currentPage_Parent - 1);
      sliderRef_Parent.current.slickPrev();
    }
  };
  return (
    <div className="parents_SectionThankyouPage">
      <div className="psrents_SectionThankyouPage_heading">
        <h1>Our Partners</h1>
      </div>

      <div className="slider_container_thank">
        <Slider {...settings_Parent} ref={sliderRef_Parent}>
          {slides_Parent.map((slide_Parent) => (
            <div key={slide_Parent.id} className="slide_thank">
              {/* <img
                src={slide_Parent.imageSrc}
                alt={`Slide_thank ${slide_Parent.id}`}
              /> */}
              <div className="image-container-parent-thank">
                <img
                  src={slide_Parent.imageSrc}
                  alt={`Slide ${slide_Parent.id}`}
                  style={{ filter: "grayscale(10%)" }}
                />
                <div className="overlay_OurPArtners_Parnts_thank"></div>
              </div>
              <div className="slide-content_thank">
                <h2>{slide_Parent.heading}</h2>
                <p>{slide_Parent.text}</p>
                <div className="buttoncss_thankPage">
                  <button className="Navbar_Login_Button_patnerThank">
                    {slide_Parent.buttonText}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <div className="slider_nav_thank">
          <div
            className={`prevArrow_Six_thank ${
              currentPage_Parent === 1 ? "disabled" : ""
            }`}
            onClick={goToPrevPage_Parent}
            disabled={currentPage_Parent === 1}
          >
            <img src="/assets/img/Partners/arrow.png" alt="dcs" />
          </div>
          <p className="PtagToatalPages_thank">{`${currentPage_Parent}/${slides_Parent.length}`}</p>
          <div
            className={`nextArrow_Six_thank ${
              currentPage_Parent === slides_Parent.length ? "disabled" : ""
            }`}
            onClick={goToNextPage_Parent}
            disabled={currentPage_Parent === slides_Parent.length}
          >
            <img src="/assets/img/Partners/arrow.png" alt="dcs" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Parents_thank;
