import React, { useEffect, useState } from "react";
import "./Notification.css";
import FotterAll from "../../Components/Footer/FotterAll";
import MainNavbar from "../../Components/Navbar/MainNavbar";
import StaticDataService from '../../services/staticData'


const Notification = () => {
  const [alerts, setAlerts] = useState([]);

  useEffect(()=>{
    StaticDataService.ServiceInfo()
    .then((response) => {
        var alerts1 = []
        response.infoData.map(alert => {
          alerts1.push(alert.message);
        });
        setAlerts(alerts1);
    })

}, []);


  return (
    <div>
      <div className="navbarContainer-sticky Main-navbar-border"><MainNavbar isHomepage={false} /></div>
      <div className="Notification_Container">
        <div className="Notification_Heading">
          <h1>Alerts And Notification</h1>
        </div>
        {alerts.map((alert,index)=>(
          <div className="Notification_content"  key={index}>
            <div className="Notification_content_Div1">
              <div className="Notification_content_Div1_img">
                <img src="/assets/img/BookingFlow/Notification_Watch.png" alt="watch"/>
              </div>
              <div className="Notification_content_Div1_heading">
                <h5>{alert}</h5> 
                {/* <p>2 Hours Ago</p> */}
              </div>
            </div>
            </div>
         ))}      
      </div>
      <div className="Notification_Fotter"> <FotterAll /></div>
    </div>
  );
};

export default Notification;
