import React from "react";
import "./ErrorPage.css";
import MainNavbar from "../../Components/Navbar/MainNavbar";
import FotterAll from "../../Components/Footer/FotterAll";
import {
  MdOutlineEmail,
  MdOutlineMailOutline,
  MdOutlineMarkEmailRead,
  MdOutlineWifiCalling3,
} from "react-icons/md";

const ErrorPage = () => {
  return (
    <div className="ErrorPage_Container">
      <div className="NavbarContierStickyError">
        <MainNavbar />
      </div>
      <div className="ErrorPageContent">
        <div className="ErrorPageContent_Div1">
          <div className="EmailError">
            <MdOutlineMailOutline />
            <p>Abc@M2mferries.Com</p>
          </div>
          <div className="EmailError">
            <MdOutlineWifiCalling3 />
            <p>+ 91 8291902662</p>
          </div>
        </div>

        <div className="ErrorPage_MainContet">
          <div className="ErrorDiv1">
            <img src="/assets/img/Error/ErrorImg.png" alt="Error" />
          </div>
          <div className="ErrorDiv2">
            <h1>Sorry Your Booking Could Not Be Completed !</h1>
            <p>The Payment For This Transection Has Failed</p>
          </div>
          <div className="ErrorDiv3">
            <p>
              Sorry, your booking could not be completed because this payment
              for this transaction has failed. Please check your payment details
              or try booking through another of our payment options like
              credit/debit cards or mobile payments. in case of queries please
              contact us on helpdesk@M2M.com.
            </p>
          </div>
          <div>
            <button className="ErrorPageButton">Go To Home</button>
          </div>
        </div>
      </div>
      <div>
        <FotterAll />
      </div>
    </div>
  );
};

export default ErrorPage;
