import React, { useState } from 'react';
import './Explore_Tabs_Gallery.css';
import Modal from 'react-modal';
import EventsPage from '../../Pages/Onward_Experience_Pages/EventsPage';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import { useLocation } from 'react-router-dom';
import { M2MApiUrl } from "../../services/staticData";


Modal.setAppElement('#root');

const Explore_Tabs_Gallery = ({currentGallryData}) => {

  const [modalIsOpen,setModalIsOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);

  const openModal = () => {
    const images = JSON.parse(currentGallryData.post_images).map((imageUrl) =>
      `${M2MApiUrl}${imageUrl.replace("public/", "storage/")}`
    );
    setSelectedImages(images);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImages([]); 
  };

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: nav2,
  };

  const settingsThumbs = {
    slidesToShow: 4.2,
    slidesToScroll: 1,
    asNavFor: nav1,
    focusOnSelect: true,
    vertical: true,
    verticalSwiping: true,
    arrows: false,
  };
  const settingsThumbsmobile = {
    slidesToShow: 2.5,
    slidesToScroll: 1,
    asNavFor: nav1,
    focusOnSelect: true,
    vertical: false,
    verticalSwiping: false,
    arrows: false,
    infinite: false,
  };


  return (
    <>
      <div className="image-grid-container">
        <div className="main-image-left-side">
          <img  src={`${M2MApiUrl}${currentGallryData.hero_image.replace("public/", "storage/")}`}  alt={currentGallryData.title} />
        </div>
        {/* <div className="many-image-right-side">
          <div className="right-side-1">
              <img src="/assets/img/explore-page/explore-comman-content-1.png" alt="Main" />
          </div>
          <div className="right-side-1">
              <img src="/assets/img/explore-page/explore-comman-content-1.png" alt="Main" />
          </div>
          <div className="right-side-1">
              <img src="/assets/img/explore-page/explore-comman-content-1.png" alt="Main" />
          </div>
          <div className="right-side-1 right-side-last-box-content" onClick={(openModal)}>
              <img src="/assets/img/explore-page/explore-comman-content-1.png" alt="Main" />
              <div className='all-image-see-content'>
                <img src="/assets/img/explore-page/gallery-img.png" alt="Gallery" />
                <h1>See All 20 Photos</h1>
              </div>
          </div>
        </div> */}
        <div className="many-image-right-side">
          {currentGallryData.post_images && 
            JSON.parse(currentGallryData.post_images).slice(0, 4).map((imageUrl, index) => (
              <div className={`right-side-1 ${index === 3 ? 'right-side-last-box-content' : ''}`} key={index}>
                <img src={`${M2MApiUrl}${imageUrl.replace("public/", "storage/")}`} alt={currentGallryData.title} />
                
                {index === 3 && (
                  <div className='all-image-see-content' onClick={openModal}>
                    <img src="/assets/img/explore-page/gallery-img.png" alt="Gallery" />
                    {/* <h1>See All {currentGallryData.post_images ? JSON.parse(currentGallryData.post_images).length : 0} Photos</h1> */}
                    <h1>See All</h1>
                  </div>
                )}
              </div>
            ))
          }
        </div>


      </div>

      <Modal isOpen={modalIsOpen}onRequestClose={closeModal}contentLabel='Gallery Modal'className="explore-modal-content"overlayClassName="explore-modal-overlay">
          <div className="gallery-page-moddal-open">
            <div className="slider-wrapper">
              <div className="main-slider">
                <Slider {...settingsMain} ref={setNav1}>
                  {/* {HorizontalSlides[0].map((image, index) => (
                    <div key={index} className="slick_slide_main">
                      <img src={image.image} alt={image.text} className="main-image" />
                      <div className="slide_content_horizontal">
                        <p>{image.text}</p>
                      </div>
                    </div>
                  ))} */}
                   {selectedImages.map((image, index) => (
                    <div key={index} className="slick_slide_main">
                      <img src={image} alt={`Slide ${index + 1}`} className="main-image" />
                    </div>
                  ))}
                </Slider>
              </div>
              <div className="thumbnail-slider-wrapper">
                <Slider {...settingsThumbs} ref={setNav2}>
                  {selectedImages.map((image, index) => (
                    <div key={index}>
                      <img src={image} alt={`Thumbnail ${index + 1}`} className="thumbnail-image" />
                    </div>
                  ))}
                </Slider>
              </div>
              <div className="thumbnail-slider-wrapper-mobile">
                <Slider {...settingsThumbsmobile} ref={setNav2}>
                 {selectedImages.map((image, index) => (
                    <div key={index}>
                      <img src={image} alt={`Thumbnail ${index + 1}`} className="thumbnail-image-mobile" />
                    </div>
                  ))}
                </Slider>
              </div>
              <button className="close-button-slider-gallery" onClick={closeModal}>×</button>
            </div>
          </div>
      </Modal>
    </>
  )
}

export default Explore_Tabs_Gallery