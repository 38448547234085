import React, { useRef, useState } from "react";
import "./Explore_Vendors_Slider.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Explore_Vendors_Slider = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const sliderRef = useRef();
    
    // Example dynamic data
    const data = [
        {
            heading: "Ashok Surfing",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam hendrerit nisi sed sollicitudin pellentesque. Nunc posuere purus rhoncus pulvinar aliquam",
            image: "/assets/img/explore-page/BlueWaveCrop.png",
            contact: {
                phone: "+91 6546546547786515",
                email: "support@m2mferries.com"
            },
            pricing: {
                surfing: "₹ 1500",
                fullPackage: "₹ 3000"
            }
        },
        {
            heading: "Ashok Surfing",
            description: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            image: "/assets/img/explore-page/BlueWaveCrop.png",
            contact: {
                phone: "+91 1234567890",
                email: "support@m2mferries.com"
            },
            pricing: {
                surfing: "₹ 1500",
                fullPackage: "₹ 3000"
            }
        },
        {
            heading: "Ashok Surfing",
            description: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            image: "/assets/img/explore-page/BlueWaveCrop.png",
            contact: {
                phone: "+ 91 8291902662",
                email: "support@m2mferries.com"
            },
            pricing: {
                surfing: "₹ 1500",
                fullPackage: "₹ 3000"
            }
        },
        {
            heading: "Ashok Surfing",
            description: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            image: "/assets/img/explore-page/BlueWaveCrop.png",
            contact: {
                phone: "+ 91 8291902662",
                email: "support@m2mferries.com"
            },
            pricing: {
                surfing: "₹ 1500",
                fullPackage: "₹ 3000"
            }
        },
        {
            heading: "Ashok Surfing",
            description: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            image: "/assets/img/explore-page/BlueWaveCrop.png",
            contact: {
                phone: "+ 91 8291902662",
                email: "support@m2mferries.com"
            },
            pricing: {
                surfing: "₹ 1500",
                fullPackage: "₹ 3000"
            }
        },
        {
            heading: "Ashok Surfing",
            description: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            image: "/assets/img/explore-page/BlueWaveCrop.png",
            contact: {
                phone: "+ 91 8291902662",
                email: "support@m2mferries.com"
            },
            pricing: {
                surfing: "₹ 1500",
                fullPackage: "₹ 3000"
            }
        },
        {
            heading: "Ashok Surfing",
            description: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            image: "/assets/img/explore-page/BlueWaveCrop.png",
            contact: {
                phone: "+ 91 8291902662",
                email: "support@m2mferries.com"
            },
            pricing: {
                surfing: "₹ 1500",
                fullPackage: "₹ 3000"
            }
        },
        // Add more data objects as needed
    ];

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        afterChange: (currentSlide) => {
            setCurrentPage(currentSlide + 1);
        },
        responsive: [
            {
                breakpoint: 968,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    afterChange: (currentSlide) => {
                        setCurrentPage(currentSlide + 1);
                    },
                },
            },
        ],
    };

    const totalPage = data.length;

    const goToNext = () => {
        sliderRef.current.slickNext();
    };

    const goToPrev = () => {
        sliderRef.current.slickPrev();
    };

    return (
        <div className='explore-vendor-slider-section'>
            <h1>Vendors</h1>
            <div className="about-mandwa-heading-bottam-line">
                <div className="section bottam-line-1"></div>
                <div className="section bottam-line-2"></div>
                <div className="section bottam-line-3"></div>
            </div>
            <div className="slider_container">
                <Slider ref={sliderRef} {...settings}>
                    {data.map((item, index) => (
                        <div key={index} className="slides">
                            <h1>{item.heading}</h1>
                            <div className='contact-us-section'>
                                <h2>Contact Details</h2>
                                <div className='contact-us-section-div'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 18.106 18.137">
                                        <path id="Icon_feather-phone-call" data-name="Icon feather-phone-call" d="M13.078,4.564A3.83,3.83,0,0,1,16.1,7.59M13.078,1.5a6.894,6.894,0,0,1,6.09,6.082M18.4,13.694v2.3a1.532,1.532,0,0,1-1.67,1.532,15.159,15.159,0,0,1-6.61-2.352,14.937,14.937,0,0,1-4.6-4.6A15.159,15.159,0,0,1,3.174,3.936,1.532,1.532,0,0,1,4.7,2.266H7A1.532,1.532,0,0,1,8.528,3.583a9.835,9.835,0,0,0,.536,2.152A1.532,1.532,0,0,1,8.72,7.352l-.973.973a12.256,12.256,0,0,0,4.6,4.6l.973-.973a1.532,1.532,0,0,1,1.616-.345,9.835,9.835,0,0,0,2.152.536A1.532,1.532,0,0,1,18.4,13.694Z" transform="translate(-2.167 -0.396)" fill="none" stroke="#01181f" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                    </svg>
                                    <p>{item.contact.phone}</p>
                                </div>
                                <div className='contact-us-section-div'>     
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12.8" viewBox="0 0 16 12.8">
                                       <path id="Icon_material-mail-outline" data-name="Icon material-mail-outline" d="M17.4,6H4.6A1.6,1.6,0,0,0,3.008,7.6L3,17.2a1.6,1.6,0,0,0,1.6,1.6H17.4A1.6,1.6,0,0,0,19,17.2V7.6A1.6,1.6,0,0,0,17.4,6Zm0,11.2H4.6v-8l6.4,4,6.4-4ZM11,11.6l-6.4-4H17.4Z" transform="translate(-3 -6)" fill="#01181f"/>
                                    </svg>
                                    <p>{item.contact.email}</p>
                                </div>
                            </div>
                            <div className='contact-us-section-pricing'>
                                <div className='contact-us-section-pricing-div-1'>
                                    <h2>{item.pricing.surfing}</h2>
                                    <p>Price for Surfing</p>
                                </div>
                                <div className="contact-us-section-pricing-vertical-line"></div>
                                <div className="contact-us-section-pricing-div-1">
                                    <h2>{item.pricing.fullPackage}</h2>
                                    <p>Full Package</p>
                                </div>
                            </div>
                            <div>
                                <img src={item.image} alt={item.heading} />
                            </div>
                        </div>
                    ))}
                </Slider>
                <div className="pagination_Explore_about">
                    <div className="prev_button_eight_about" onClick={goToPrev} disabled={currentPage === 1}>
                        <img src="/assets/img/Partners/arrow.png" alt="prev" />
                    </div>
                    <div className="page_indicator_about">{currentPage}/{totalPage}</div>
                    <div className="next_button_eight_about" onClick={goToNext} disabled={currentPage === totalPage}>
                        <img src="/assets/img/Partners/arrow.png" alt="next" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Explore_Vendors_Slider;
