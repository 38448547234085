import React from "react";
import "./DepartureM2M.css";
// import SliderImag from "../../Components/OnwordBooking_Comp/CommanComp/SliderImag";
import DepartureFlow from "../../Components/OnwordBooking_Comp/DepartureFlow";
import FotterAll from "../../Components/Footer/FotterAll";
import MainNavbar from "../../Components/Navbar/MainNavbar";

const DepartureM2M = () => {
  return (
    <div className="BookingFlowPage1">
      <div className="content_DepartureM2M">
      <div className="Navbar_Sticky_Pages">
        <MainNavbar isHomepage={false} />
        </div>

        {/* <SliderImag /> */}
        <DepartureFlow />
      </div>

      {/* <Pratice /> */}
      <FotterAll />
    </div>
  );
};

export default DepartureM2M;
