import React, { useEffect,useState } from "react";
//import Calendar from "./BigCalender";
import FullCalendar from "./FullCalendar";
import FotterAll from "../../Components/Footer/FotterAll";
import "./Schedule.css";
import MainNavbar from "../../Components/Navbar/MainNavbar";
import Meta from '../../Components/Meta';
import StaticDataService from "../../services/staticData";

const Schedule = () => {
  const [seoMeta, setSeoMeta] = useState(null);

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  //FOR META TAGS
  useEffect(() => {
      StaticDataService.SeoMetaServices('Schedule')
      .then((response) => {
       
        if (response.status !== "error") {
          
          setSeoMeta(response.seoMeta[0]);
         
        } else {
          /* setError(response.message); */
        }
      })
      .catch((err) => {
        //setError(err.message);
      });
  }, []);

  return (
    <div>
      {seoMeta ? (
        <Meta
          title={seoMeta.title}
          description={seoMeta.description}
          keywords={seoMeta.keywords}
          site_name = {seoMeta.site_name}
          canonicalUrl = {seoMeta.canonicalUrl}
          url={seoMeta.url}
          image = {seoMeta.image}
        />
      ):null}
      <div className="navbarContainer-sticky Main-navbar-border">
        <MainNavbar />
      </div>
      <div className="Schedule_Section">
        <div className="Schedule_Section_Heading">
          <h1>Schedule</h1>
          <p>Smooth sailing starts here. Explore the upcoming schedules to plan your getaway.</p>{" "}
        </div>
        <div className="Schedule_WhiteLinearGradint"></div>
      </div>
      <div className="SchedulePageMainContiner">
        <FullCalendar />
      </div>

      <FotterAll />
    </div>
  );
};

export default Schedule;
