import React from "react";
import "./ReturnM2M.css";
// import SliderImag from "../../Components/OnwordBooking_Comp/CommanComp/SliderImag";
import ReturnFlow from "../../Components/OnwordBooking_Comp/ReturnFlow";
import FotterAll from "../../Components/Footer/FotterAll";
import MainNavbar from "../../Components/Navbar/MainNavbar";

const ReturnM2M = () => {
  return (
    <div className="BookingFlowPage1">
      <div className="Navbar_Sticky_Pages">
        <MainNavbar isHomepage={false} />
      </div>
      <div className="content_ReturnM2M">
        {/* <SliderImag /> */}
        <ReturnFlow />
      </div>

      <FotterAll />
    </div>
  );
};

export default ReturnM2M;
