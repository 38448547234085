import React, { useRef, useState } from "react";
import "./Explore_SubTabsPage.css";
import MainNavbar from '../../Components/Navbar/MainNavbar';
import FotterAll from '../../Components/Footer/FotterAll';
import Explore_Tabs_Gallery from '../../Components/Explore_Components/Explore_Tabs_Gallery';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Explore_Comman_slider_Tabs from "../../Components/Explore_Components/Explore_Comman_slider_Tabs";
import Explore_location_Comman from "../../Components/Explore_Components/Explore_location_Comman";
import { useNavigate } from 'react-router-dom';
import Explore_Slider_Hotel from "../../Components/Explore_Components/Explore_Slider_Hotel";

const Explore_SubTabsPage_Hotels = () => {
  const navigate = useNavigate();


  const handleSubmitReverse = () => {
    navigate('/explore');
  };
  return (
    <div className='explore-comman-tabs-container'>
        <div className="navbarContainer-sticky Main-navbar-border">
          <MainNavbar />
        </div>
        <div className="explore-comman-tabs-heading-content">
            <div className="back-to-home-page">
                    <h1 onClick={handleSubmitReverse}>Explore Mandwa Port</h1>
                    <svg className="back-to-home-page-svg" xmlns="http://www.w3.org/2000/svg" width="12.705" height="21.229" viewBox="0 0 12.705 21.229">
                      <path id="Alt_Arrow_Down" data-name="Alt Arrow Down" d="M21.988,9l-8.494,9.084L5,9" transform="translate(-6.88 24.109) rotate(-90)" fill="none" stroke="#767c8d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                    </svg>
                   <h3>Alto Vino Restaurant & Bar</h3>
            </div>
          <div className="explore-comman-tabs-heading">
            <h1>Alto Vino Restaurant & Bar</h1>
          </div>
          <div className="white-liner-gradient"></div>
        </div>

        <div className='explore-comman-gallery'>
          <Explore_Tabs_Gallery />
        </div>

        <div className='explore-comman-about-comp'>
            <div className='explore-comman-about-comp-div-1'>
              <h1>About Surfing At Mandwa Beach</h1>
              <div className="about-mandwa-heading-bottam-line" style={{margin:0}}>
                      <div className="section bottam-line-1"></div>
                      <div className="section bottam-line-2"></div>
                      <div className="section bottam-line-3"></div>
              </div>
            </div>
            <div className="explore-comman-about-comp-div-2">
              <h5>5000 ₹</h5>
              <h2>Starting cost per person</h2>
              <p>The Surf Wala surf school was established on Arambol Beach, North Goa, in 2010 with a simple desire in mind; to share our passion for surfing with anyone, locals and visitors alike, who might wish to join the growing community of surfers here. Our little tribe of board riders has steadily increased as news has spread that it is, in fact, possible to surf in Goa. Surf Wala exists in order to continue to encourage that message, to provide surf board hire, surf lessons, equipment and advice to anyone who feels enticed to begin surfing in Goa, and most of all to provide a base for these people to come together.</p>
                  <button className="row-button-schedule-BookNow"  type="submit">Know More</button>
            </div>
        </div>

        <div className='explore-overview-hotel'>
              <h1>Timings And Menu</h1>
              <div className="about-mandwa-heading-bottam-line" style={{paddingBottom:'25px'}}>
                        <div className="section bottam-line-1"></div>
                        <div className="section bottam-line-2"></div>
                        <div className="section bottam-line-3"></div>
              </div>

          <div className="explore-container-hotel">
                <div className="columns-hotel-div-1">
                  <div className="column-hotel">
                    <h3>Bestselling Items</h3>
                    <ul>
                      <li>Paneer Ghee Roast</li>
                      <li>Veg Dum Biryani</li>
                      <li>Butter Chicken</li>
                      <li>Chicken Triple</li>
                      <li>Schezwan Rice</li>
                    </ul>
                  </div>
                  <div className="column-hotel-1">
                    <h3>Type</h3>
                    <ul>
                      <li>Dineout Pay, Casual Dining</li>
                    </ul>
                    <h3>Cuisine</h3>
                    <ul>
                      <li>North Indian, Chinese, Biryani</li>
                    </ul>
                    <h3>Time</h3>
                    <ul>
                      <li>09:00 AM to 09:00 PM</li>
                    </ul>
                  </div>
                </div>
                <div className="columns-hotel-div-2">
                  <h3>Facilities and features</h3>
                  <ul>
                    <li>Home Delivery</li>
                    <li>Smoking Area</li>
                    <li>Air Conditioned</li>
                    <li>Parking</li>
                    <li>Card Accepted</li>
                  </ul>
                </div>
            </div>
         </div>

        <div>
          <Explore_location_Comman/>
        </div>

        <div>
          <Explore_Slider_Hotel/>
        </div>

        <div>
          <FotterAll />
        </div>
   </div>
  )
}

export default Explore_SubTabsPage_Hotels