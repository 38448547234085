import React from "react";
import "./ErrorPage.css";
import MainNavbar from "../../Components/Navbar/MainNavbar";
import FotterAll from "../../Components/Footer/FotterAll";
import {
  MdOutlineEmail,
  MdOutlineMailOutline,
  MdOutlineMarkEmailRead,
  MdOutlineWifiCalling3,
} from "react-icons/md";


const BookingFailure = ({booking_id}) => {
  const bookingId = booking_id;
  
    
  return (
    <>
      <div className="ErrorPageContent">
          <div className="ErrorPageContent_Div1">
            <div className="EmailError">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12.8" viewBox="0 0 16 12.8">
                <path id="Icon_material-mail-outline" data-name="Icon material-mail-outline" d="M17.4,6H4.6A1.6,1.6,0,0,0,3.008,7.6L3,17.2a1.6,1.6,0,0,0,1.6,1.6H17.4A1.6,1.6,0,0,0,19,17.2V7.6A1.6,1.6,0,0,0,17.4,6Zm0,11.2H4.6v-8l6.4,4,6.4-4ZM11,11.6l-6.4-4H17.4Z" transform="translate(-3 -6)" fill="#01181f"/>
              </svg>
              <a href="mailto:support@m2mferries.com">
                  <p>support@m2mferries.com</p>
              </a>
            </div>
            <div className="EmailError">
              <svg xmlns="http://www.w3.org/2000/svg" width="18.106" height="18.137" viewBox="0 0 18.106 18.137">
                <path id="Icon_feather-phone-call" data-name="Icon feather-phone-call" d="M13.078,4.564A3.83,3.83,0,0,1,16.1,7.59M13.078,1.5a6.894,6.894,0,0,1,6.09,6.082M18.4,13.694v2.3a1.532,1.532,0,0,1-1.67,1.532,15.159,15.159,0,0,1-6.61-2.352,14.937,14.937,0,0,1-4.6-4.6A15.159,15.159,0,0,1,3.174,3.936,1.532,1.532,0,0,1,4.7,2.266H7A1.532,1.532,0,0,1,8.528,3.583a9.835,9.835,0,0,0,.536,2.152A1.532,1.532,0,0,1,8.72,7.352l-.973.973a12.256,12.256,0,0,0,4.6,4.6l.973-.973a1.532,1.532,0,0,1,1.616-.345,9.835,9.835,0,0,0,2.152.536A1.532,1.532,0,0,1,18.4,13.694Z" transform="translate(-2.167 -0.396)" fill="none" stroke="#01181f" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
              </svg>
              <a href={`tel:+ 91 8291902662`}>
                <p>+ 91 8291902662</p>
              </a>
            </div>
          </div>

          <div className="ErrorPage_MainContet">
            <div className="ErrorDiv1">
              <img src="/assets/img/Error/ErrorImg.png" alt="Error" />
            </div>
            <div className="ErrorDiv2">
              <h1>Sorry Your Booking Could Not Be Completed !</h1>
              {/* <p>The Payment For This Transection Has Failed</p> */}
            </div>
            <div className="ErrorDiv3">
              <p>
              The payment for this transaction has failed. <br />
              Please check your payment details and try again.<br />
              In case of any queries, you can email us at  <a href="mailto:support@m2mferries.com">support@m2mferries.com</a> or call at  <a href={`tel:+ 91 8291902662`}>+91 8291902662</a>.
              </p>
            </div>
            <div>              
              <a className="link-without-underline " href="/"><button className="ErrorPageButton">Try Again</button></a>
            </div>
          </div>
        </div>
    </>
  )
}

export default BookingFailure
