import React from 'react';
import { Helmet } from 'react-helmet';

const Meta = ({ title, description, keywords, site_name, canonicalUrl, url, image }) => {
    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={image} />
        <meta name="twitter:title" content={title} />
        <meta property="og:site_name" content={site_name} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        <link rel="canonical" href={canonicalUrl} />
       
      </Helmet>
    );
  };

export default Meta;
